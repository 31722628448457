import React, {useState} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import MessageTemplate from './message-template/message-template.jsx';
import Editor from './editor/editor.jsx';
import Section from '../../../components/section/section.jsx';
import * as Title from '../../../components/title/title.jsx';
import Text from '../../../components/text/text.jsx';
import Link from '../../../components/link/link.jsx';
import Grid from '../../../components/grid/grid.jsx';
import Wrapper from '../../../components/wrapper/wrapper.jsx';
import Button from '../../../components/button/button.jsx';
import Icons from '../../../components/icons/icons.jsx';
import Preloader from '../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../app-context.js';
import {useRequest} from '../../../common/hooks.js';
import {createMessageTemplateReq} from '../../../common/api/api.js';
import {useHomeContext} from '../home-context.js';
import {useProcessAttachmentList} from './hooks.js';

const Automation = () => {
  const {user, lang} = useAppContext();
  const {messageTemplateList, setMessageTemplateList} = useHomeContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();
  const processAttachmentList = useProcessAttachmentList();

  const handleSubmit = async ({values, attachmentList}) => {
    const res = await request(
      createMessageTemplateReq({
        crm: user.crm,
        domain: user.domain,
        data: values
      })
    );

    if (Object.keys(res).length) {
      setMessageTemplateList(prevValue =>
        update(prevValue, {
          $unshift: [{...res, file_count: attachmentList.length}]
        })
      );

      processAttachmentList({
        messageTemplateId: res.id,
        attachmentList
      });
    }
  };

  return (
    <>
      <Section title={t`Automation`}>
        <Wrapper
          gap={4}
          isColumn
          style={{marginBottom: 16}}
        >
          <Title.H3>{t`Message templates`}</Title.H3>

          {lang === 'ru' && (
            <Text>
              <Link
                href="https://whatcrm.ru/help/tpost/tkzynrsbe1-shabloni-whatcrm"
                target="_blank"
                isSmall
                isBold
              >
                Руководство по созданию
              </Link>
            </Text>
          )}
        </Wrapper>

        <Button
          style={{marginBottom: 16}}
          onClick={() => setIsOpen(true)}
        >
          <Icons.Plus />
          {t`Create a message template`}
        </Button>

        {messageTemplateList ? (
          messageTemplateList.length ? (
            <Grid>
              {messageTemplateList.map(item => (
                <MessageTemplate
                  key={item.id}
                  messageTemplate={item}
                />
              ))}
            </Grid>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Section>

      {isOpen && (
        <Editor
          headline={t`Create a message template`}
          onClose={() => setIsOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default Automation;
