import React, {useState} from 'react';
import {t} from 'ttag';
//import WhatcrmProductList from './whatcrm-product-list/whatcrm-product-list.jsx';
//import TelphinProductList from './telphin-product-list/telphin-product-list.jsx';
import IntegrationList from './integration-list/integration-list.jsx';
import Section from '../../../components/section/section.jsx';
import {NavTab} from '../../../components/nav/nav.jsx';
import {useAppContext} from '../../../app-context.js';

const Products = () => {
  const {user} = useAppContext();

  const [tab, setTab] = useState('integrationList');

  const options = [];

  if (user.crm === 'LK') {
    options.push({
      value: 'integrationList',
      label: t`Integrations`
    });
  }

  const navParams = {
    options: options,
    value: tab,
    onChange: setTab
  };

  return (
    <Section
      title={t`Integrations`}
      navParams={navParams}
    >
      <NavTab tab="integrationList">
        <IntegrationList />
      </NavTab>
    </Section>
  );
};

export default Products;
