import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import Name from '../../../../../name/name.jsx';
import Wrapper from '../../../../../../../wrapper/wrapper.jsx';
import QR from '../../../../../../../qr/qr.jsx';
import Text from '../../../../../../../text/text.jsx';
import Link from '../../../../../../../link/link.jsx';
import List from '../../../../../../../list/list.jsx';
import {useAppContext} from '../../../../../../../../app-context.js';
import {useConnectionContext} from '../../../../../connection-context.js';
import {useCopyToClipboard} from '../../../../../../../../common/hooks.js';

const Scanning = ({startContainer}) => {
  const {whatsAppSupport} = useAppContext();
  const {connection, connectionStatus} = useConnectionContext();

  const copyToClipboard = useCopyToClipboard();

  const manuals = {
    telegram: [
      t`Wait for the QR code`,
      t`Open Telegram on your phone`,
      t`Go to Settings → Devices → Link Desktop Device`,
      t`Use your phone to scan the QR code and wait 1 minute`
    ],
    whatcrm: [
      t`Wait for the QR code`,
      t`Open WhatsApp on your phone`,
      t`Go to Settings → Linked Devices → Link a Device`,
      t`Use your phone to scan the QR code and wait 1 minute`,
      t`Keep your phone connected while using Whatsapp Web`
    ],
    whatsapp: [
      t`Wait for the QR code`,
      t`Open WhatsApp on your phone`,
      t`Go to Settings → Linked Devices → Link a Device`,
      t`Use your phone to scan the QR code and wait 1 minute`,
      t`Keep your phone connected while using Whatsapp Web`
    ]
  };

  const isPending =
    connectionStatus.state === 'READY' ||
    connectionStatus.state === 'PENDING' ||
    connectionStatus.state === 'CONNECTED' ||
    connectionStatus.state === 'NAVIGATION';

  return (
    <Wrapper
      alignItems="stretch"
      gap={24}
    >
      <div style={{flex: '0 1'}}>
        <QR
          qr={connectionStatus.qrCode}
          isPending={isPending}
          style={{marginBottom: 16}}
          onClick={startContainer}
        />

        <Text style={{marginBottom: 8}}>
          <Link
            isFake
            onClick={() =>
              copyToClipboard(
                `${window.location.origin}/scanner/${connection.chat_key}`
              )
            }
          >
            {t`Copy the link to the QR code`}
          </Link>
        </Text>

        <Text>
          {t`If you can not scan the QR code, contact`}{' '}
          <Link
            href={whatsAppSupport}
            target="_blank"
          >
            {t`support`}
          </Link>
          .
        </Text>
      </div>

      <div style={{flex: '1 0'}}>
        <Name style={{marginBottom: 16}} />
        <List items={manuals[connection.version]} />
      </div>
    </Wrapper>
  );
};

Scanning.propTypes = {
  startContainer: PropTypes.func
};

Scanning.defaultProps = {
  startContainer: () => {}
};

export default Scanning;
