import React, {useState, useEffect} from 'react';
import Error403 from './error-403/error-403.jsx';
import Connections from './connections/connections.jsx';
import Automation from './automation/automation.jsx';
import DenyList from './deny-list/deny-list.jsx';
import Products from './products/products.jsx';
import Partners from './partners/partners.jsx';
import NoticeList from './notice-list/notice-list.jsx';
import Page from '../../components/page/page.jsx';
import Preloader from '../../components/preloader/preloader.jsx';
import HomeContext from './home-context.js';
import {useAppContext} from '../../app-context.js';
import {useRequest} from '../../common/hooks.js';
import {getDashboardReq} from '../../common/api/api.js';
import {getConnectionCondition} from '../../common/actions.js';

const Home = () => {
  const {user, lang, isForbidden, setUser, setIsForbidden} = useAppContext();

  const [connectionList, setConnectionList] = useState();
  const [noticeList, setNoticeList] = useState();
  const [messageTemplateList, setMessageTemplateList] = useState();

  const {request} = useRequest();

  const getDashboard = async () => {
    const res = await request(
      getDashboardReq({
        crm: user.crm,
        domain: user.domain,
        lang
      })
    );

    if (Object.keys(res).length) {
      const {
        instances,
        integration,
        notifications,
        partner = {},
        templates = []
      } = res;

      const instanceList = Object.entries(instances).map(item => ({
        id: parseInt(item[0]),
        ...item[1],
        ...getConnectionCondition(item[1])
      }));

      const tempUser = {...user, ...integration, partner};

      setUser(tempUser);
      setNoticeList(notifications);
      setMessageTemplateList(templates);
      setConnectionList(instanceList);

      return;
    }

    setIsForbidden(true);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <HomeContext.Provider
      value={{
        connectionList,
        messageTemplateList,
        setConnectionList,
        setMessageTemplateList
      }}
    >
      {isForbidden ? (
        <Error403 />
      ) : connectionList ? (
        <div className="home">
          <Connections />
          <Automation />
          <DenyList />
          <Products />
          <Partners />

          <NoticeList
            noticeList={noticeList}
            setNoticeList={setNoticeList}
          />
        </div>
      ) : (
        <Page
          isCenter
          isTransparent
        >
          <Preloader />
        </Page>
      )}
    </HomeContext.Provider>
  );
};

export default Home;
