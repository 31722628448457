import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactCollapsible from 'react-collapsible';
import {
  TableRow,
  TableCell
} from '../../../../../../components/table/table.jsx';
import ConnectionList from '../../../../../../components/connection-list/connection-list.jsx';
import {useCustomerContext} from '../customer-context.js';
import {useRequest} from '../../../../../../common/hooks.js';
import {getConnectionListReq} from '../../../../../../common/api/api.js';
import {
  getConnectionCondition,
  scrollToPosition
} from '../../../../../../common/actions.js';

const AdditionalInfo = ({isOpen}) => {
  const {customer} = useCustomerContext();

  const [connectionList, setConnectionList] = useState();

  const ref = useRef();

  const {request} = useRequest();

  const getConnectionList = async () => {
    const res = await request(
      getConnectionListReq({
        crm: customer.type_crm,
        domain: customer.domain
      })
    );

    const instances = Object.entries(res).map(item => ({
      id: parseInt(item[0]),
      ...item[1],
      ...getConnectionCondition(item[1])
    }));

    setConnectionList(instances);
  };

  useEffect(() => {
    getConnectionList();
  }, []);

  useEffect(() => {
    if (isOpen) {
      const {top} = ref.current.getBoundingClientRect();
      scrollToPosition(top);
    }
  }, [isOpen]);

  return (
    <TableRow
      ref={ref}
      isOpen={isOpen}
      isCollapsible={true}
    >
      <TableCell isFullWidth>
        <ReactCollapsible
          transitionTime={200}
          open={isOpen}
        >
          <ConnectionList
            connectionList={connectionList}
            client={{
              crm: customer.type_crm,
              domain: customer.domain,
              isCustomer: true
            }}
            setConnectionList={setConnectionList}
          />
        </ReactCollapsible>
      </TableCell>
    </TableRow>
  );
};

AdditionalInfo.propTypes = {
  isOpen: PropTypes.bool
};

AdditionalInfo.defaultProps = {
  isOpen: false
};

export default AdditionalInfo;
