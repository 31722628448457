import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableRow,
  TableCell
} from '../../../../../../components/table/table.jsx';

const User = ({user}) => (
  <TableRow>
    <TableCell>{moment.unix(user.date_add).format('L')}</TableCell>
    <TableCell>{user.domain}</TableCell>
  </TableRow>
);

User.propTypes = {
  user: PropTypes.shape({
    date_add: PropTypes.number,
    domain: PropTypes.string,
    id: PropTypes.number
  })
};

export default User;
