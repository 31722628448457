import React from 'react';
import Linking from './linking/linking.jsx';
import Popup from '../../../../../popup/popup.jsx';
import Preloader from '../../../../../preloader/preloader.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';

const LinkingPopup = () => {
  const {setOpenConnectionId} = useConnectionListContext();
  const {connectionStatus} = useConnectionContext();

  return (
    <Popup
      isWide
      onClick={() => setOpenConnectionId(-1)}
    >
      {connectionStatus ? <Linking /> : <Preloader />}
    </Popup>
  );
};

export default LinkingPopup;
