import React, {useState, useEffect} from 'react';
import {t, ngettext, msgid} from 'ttag';
import PaymentRecord from './payment-record/payment-record.jsx';
import Filter from '../../../../components/filter/filter.jsx';
import Table, {
  TableHead,
  TableBody,
  TableCell
} from '../../../../components/table/table.jsx';
import Text from '../../../../components/text/text.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {getPaymentHistoryReq} from '../../../../common/api/api.js';
import {inflectNumberal} from '../../../../common/actions.js';

const PaymentHistory = () => {
  const {user, lang} = useAppContext();

  const [paymentHistory, setPaymentHistory] = useState();
  const [filteredPaymentHistory, setFilteredPaymentHistory] = useState([]);

  const {request} = useRequest();

  const getPaymentHistory = async () => {
    const res = await request(
      getPaymentHistoryReq({
        crm: user.crm,
        domain: user.domain
      })
    );

    const resValues = Object.values(res);

    setPaymentHistory(resValues);
    setFilteredPaymentHistory(resValues);
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const handleFilterChange = values => {
    if (values.domains.length) {
      setFilteredPaymentHistory(
        paymentHistory.filter(entry => values.domains.includes(entry.domain))
      );

      return;
    }

    setFilteredPaymentHistory(paymentHistory);
  };

  const domains = paymentHistory
    ? paymentHistory.reduce((acc, entry) => {
        acc.push(entry.domain);
        return acc;
      }, [])
    : [];

  const isRefund = !!(
    filteredPaymentHistory &&
    filteredPaymentHistory.find(item => item.is_deleted === '1')
  );

  return (
    <div>
      {paymentHistory ? (
        paymentHistory.length ? (
          <>
            <Filter
              domains={domains}
              style={{marginBottom: 16}}
              onChange={handleFilterChange}
            />

            {filteredPaymentHistory.length ? (
              <>
                <Text
                  isSmall
                  style={{marginBottom: 8}}
                >
                  {filteredPaymentHistory.length}
                  {' '}
                  {lang === 'ru'
                    ? inflectNumberal({
                        value: filteredPaymentHistory.length,
                        labels: ['запись', 'записи', 'записей']
                      })
                    : lang === 'uk'
                    ? inflectNumberal({
                        value: filteredPaymentHistory.length,
                        labels: ['запис', 'записи', 'записів']
                      })
                    : ngettext(
                        msgid`entry`,
                        `entries`,
                        filteredPaymentHistory.length
                      )}
                </Text>

                <Table isEmbedded>
                  <TableHead>
                    <TableCell>{`${t`Date`} (UTC+3)`}</TableCell>
                    <TableCell>{t`Amount`}</TableCell>
                    <TableCell>{t`Period`}</TableCell>
                    <TableCell>{t`Tariff plan`}</TableCell>
                    <TableCell>{t`Status`}</TableCell>
                  </TableHead>

                  <TableBody>
                    {filteredPaymentHistory.map((item, i) => (
                      <PaymentRecord
                        key={i}
                        paymentRecord={item}
                        isRefund={isRefund}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <EmptyBox />
            )}
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default PaymentHistory;
