import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';

import Page from '../../components/page/page.jsx';
import QR from '../../components/qr/qr.jsx';
import Popup from '../../components/popup/popup.jsx';
import TelegramAuthentication from '../../components/telegram-authentication/telegram-authentication.jsx';
import Icons from '../../components/icons/icons.jsx';
import Preloader from '../../components/preloader/preloader.jsx';
import EmptyBox from '../../components/empty-box/empty-box.jsx';
import {useRequest, useWebSocket} from '../../common/hooks.js';
import {getConnectionStatusReq} from '../../common/api/api.js';

const Scanner = () => {
  const {chat_key} = useParams();
  const [status, setStatus] = useState();

  const {request} = useRequest();
  const {connectWebSocket, listenWebSocket} = useWebSocket();

  const getConnectionStatus = async () => {
    let res = await request(getConnectionStatusReq({chat_key}));

    if (
      !res.state ||
      res.state === 'init' ||
      res.state === 'authorizationStateWaitPhoneNumber' ||
      res.state === 'authorizationStateWaitOtherDeviceConfirmation'
    ) {
      res = await request(
        getConnectionStatusReq({
          chat_key,
          full: 1
        })
      );
    }

    setStatus(_.pick(res, ['state', 'qrCode']));
  };

  useEffect(() => {
    getConnectionStatus();
    connectWebSocket();

    listenWebSocket({
      chat_key,
      onChange: setStatus,
      onConnect: () => setStatus({state: 'CONNECTED'})
    });
  }, []);

  const isScanned =
    status &&
    (status.state === 'LOADING_SCREEN' ||
      status.state === 'READY' ||
      status.state === 'CONNECTED');

  const isPending =
    status && (status.state === 'PENDING' || status.state === 'NAVIGATION');

  return (
    <Page
      isCenter
      isTransparent
    >
      {status ? (
        Object.keys(status).length ? (
          isScanned ? (
            <Icons.Check style={{width: 164, height: 164}} />
          ) : status.state === 'authorizationStateWaitPassword' ? (
            <Popup
              withBackground={false}
              isClosable={false}
            >
              <TelegramAuthentication
                chat_key={chat_key}
                passwordHint={status.passwordHint}
              />
            </Popup>
          ) : (
            <QR
              qr={status.qrCode}
              isPending={isPending}
              style={{marginBottom: 16}}
              onClick={getConnectionStatus}
            />
          )
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader isMin />
      )}
    </Page>
  );
};

export default Scanner;
