import React from 'react';
import {t} from 'ttag';

import Wrapper from '../../wrapper/wrapper';
import Advice from '../../advice/advice';
import Link from '../../link/link';
import {useAppContext} from '../../../app-context';
import {useConnectionListContext} from '../connection-list-context';

const Advices = () => {
    const {lang} = useAppContext();
    const {client, isConnector} = useConnectionListContext();

    const isShown =
        lang == 'ru' &&
        !isConnector &&
        !client.isCustomer &&
        ['AMO', 'B24', 'LK', 'MONDAY'].includes(client.crm);

    return (
        <>
            {isShown ? (
                <Wrapper>
                    <Advice>
                        <Link
                            href="https://whatcrm.gitbook.io/whatcrm-faq/poleznye-stati/whatsapp-rekomendacii"
                            target="_blank"
                        >
                            {'Рекомендации по работе с сервисом'}
                        </Link>
                    </Advice>

                    <Advice>
                        <Link
                            href="https://whatcrm.gitbook.io/whatcrm-faq/poleznye-stati/vypolnite-eti-rekomendacii-dlya-stabilnoi-raboty-whatsapp"
                            target="_blank"
                        >
                            {'Рекомендации для стабильной работы WhatsApp'}
                        </Link>
                    </Advice>
                </Wrapper>
            ) : (
                client.crm == 'MONDAY' && (
                    <Advice>
                        <Link
                            href="https://whatcrm.gitbook.io/whatcrm-faq/integracii/monday.com-en"
                            target="_blank"
                        >
                            {t`Guidelines for working with the service`}
                        </Link>
                    </Advice>
                )
            )}
        </>
    );
};

export default Advices;
