import React from 'react';

import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import {useStaffContext} from '../staff-context.js';

import Creating from './creating/creating.jsx';
import Searching from './searching/searching.jsx';

const Actions = () => {
  const {employeeList} = useStaffContext();

  return (
    <Wrapper
      alignItems="center"
      gap={16}
      style={{marginBottom: 16}}
    >
      <Creating />
      {employeeList?.length > 4 && <Searching />}
    </Wrapper>
  );
};

export default Actions;
