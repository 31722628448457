import React from 'react';
import PropTypes from 'prop-types';

const List = ({items}) => (
  <ul className="list">
    {items.map((item, i) => (
      <li
        key={i}
        className="list__item"
      >
        {item}
      </li>
    ))}
  </ul>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node)
};

export default List;
