import React, {useState, useRef, forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Eye from './eye/eye.jsx';
import Search from './search/search.jsx';

const Input = forwardRef(
  (
    {
      type,
      inputMode,
      name,
      value,
      placeholder,
      autoComplete,
      autoFocus,
      maxLength,
      isValid,
      isCollapsible,
      isSearch,
      isMin,
      isMax,
      isTitle,
      style,
      onChange,
      onBlur
    },
    forwardRef
  ) => {
    const [htmlType, setHtmlType] = useState(type);
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    const innerRef = useRef();
    const ref = forwardRef || innerRef;

    const handleBlur = e => {
      if (isCollapsible && !value) {
        setIsCollapsed(true);
      }

      onBlur(e);
    };

    const htmlValue = value || '';

    const htmlAutoComplete =
      typeof autoComplete === 'boolean' ? (autoComplete ? 'on' : 'off') : '';

    const isPassword = type === 'password';
    const isWithIcon = isSearch || isPassword;

    return (
      <div
        className={cn('input', {
          input_min: isMin,
          input_max: isMax,
          input_collapsible: isCollapsible,
          input_collapsed: isCollapsed
        })}
        style={style}
      >
        <input
          ref={ref}
          className={cn('field input__field', {
            field_error: !isValid,
            'input__field_with-icon': isWithIcon,
            input__field_title: isTitle
          })}
          type={htmlType}
          inputMode={inputMode}
          name={name}
          value={htmlValue}
          placeholder={placeholder}
          maxLength={maxLength}
          autoComplete={htmlAutoComplete}
          autoFocus={autoFocus}
          autoCapitalize={isSearch ? 'off' : 'on'}
          onChange={e => onChange(e.target.value)}
          onBlur={handleBlur}
        />

        {isPassword ? (
          <Eye
            ref={ref}
            htmlType={htmlType}
            setHtmlType={setHtmlType}
          />
        ) : (
          isSearch && (
            <Search
              ref={ref}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />
          )
        )}
      </div>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string,
  inputMode: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  isValid: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isSearch: PropTypes.bool,
  isMin: PropTypes.bool,
  isMax: PropTypes.bool,
  isTitle: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

Input.defaultProps = {
  type: 'text',
  inputMode: '',
  name: '',
  value: '',
  placeholder: '',
  autoComplete: null,
  autoFocus: false,
  maxLength: null,
  isValid: true,
  isCollapsible: false,
  isSearch: false,
  isMin: false,
  isMax: false,
  isTitle: false,
  style: {},
  onChange: () => {},
  onBlur: () => {}
};

export default Input;
