import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Advice = ({children, type, isSmall, style, onClick}) => (
    <div
        className={cn('advice', {
          advice_small: isSmall,
          advice_clickable: onClick,
          [`advice_type-${type}`]: type
        })}
        style={style}
        onClick={() => (onClick ? onClick() : undefined)}
    >
      {type && <div className="advice__icon" />}
      {children}
    </div>
);

Advice.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['info', 'star', 'success', 'warning']),
  isSmall: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
};

Advice.defaultProps = {
  children: <></>,
  type: '',
  isSmall: false,
  style: {},
  onClick: null
};

export default Advice;
