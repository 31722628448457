import {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import update from 'immutability-helper';
import {w3cwebsocket} from 'websocket';
import {t, c} from 'ttag';
import QRCode from 'qrcode';
import {useAppContext} from '../app-context.js';
import {inflectNumberal} from './actions.js';

export const useRequest = () => {
  const {user, lang, setNotificationList} = useAppContext();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {'X-App-Token': process.env.REACT_APP_API_KEY}
  });

  const amoInstance = axios.create({
    baseURL: process.env.REACT_APP_AMO_API,
    headers: {'X-Amo-Token': process.env.REACT_APP_AMO_API_KEY}
  });

  const alfaInstance = axios.create({
    baseURL: process.env.REACT_APP_ALFA_API,
    headers: {'X-Alfa-Token': process.env.REACT_APP_ALFA_API_KEY}
  });

  const telegramInstance = axios.create({
    baseURL: process.env.REACT_APP_TELEGRAM_API,
    headers: {'X-Amo-Token': process.env.REACT_APP_AMO_API_KEY}
  });

  const b24Instance = axios.create({
    baseURL: process.env.REACT_APP_BITRIX24_API,
    headers: {'X-Api-Token': process.env.REACT_APP_BITRIX24_API_KEY}
  });

  const telphgramInstance = axios.create({
    baseURL: process.env.REACT_APP_TELPHGRAM_API,
    headers: {'X-Api-Token': process.env.REACT_APP_BITRIX24_API_KEY}
  });

  const dadataInstance = axios.create({
    baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
    headers: {Authorization: process.env.REACT_APP_DADATA_API_KEY},
    method: 'POST'
  });

  const errorKeys = ['error', 'error_code', 'error_text'];

  const notify = text =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [{title: 'Server error', text: text}]
      })
    );

  const getFormData = data => {
    const formData = new FormData();
    Object.entries(data).map(([key, value]) => formData.append(key, value));

    return formData;
  };

  const request = async ({
    path,
    params = {},
    method = 'get',
    data = {},
    isFormData = false
  }) => {
    const formData = isFormData ? getFormData(data) : data;

    const adminParams =
      user.crm == 'AMO' || user.crm == 'B24' || user.crm == 'TELPHGRAM'
        ? {user_id: user.user_id, is_admin: user.is_admin}
        : {};

    const res = await instance(path, {
      method,
      params: {...adminParams, ...params, lang},
      data: formData
    })
      .then(res => res.data)
      .then(res => {
        const resKeys = Object.keys(res);

        const isError =
          resKeys.findIndex(item => errorKeys.includes(item)) !== -1;

        if (isError) {
          if (res.error !== 'timeout') {
            notify({
              title: t`Server error`,
              text: res.error_text || res.error
            });
          }

          return {};
        }

        return res;
      })
      .catch(e => {
        notify({text: `${e.name}, ${e.message}`});
        return {};
      });

    return res.data || res;
  };

  const crmRequest = async ({
    crm,
    path,
    params = {},
    method = 'get',
    data = {}
  }) => {
    const crmInstance =
      crm === 'B24'
        ? b24Instance
        : crm === 'AMO'
        ? amoInstance
        : crm === 'TELEGRAM'
        ? telegramInstance
        : crm === 'TELPHGRAM'
        ? telphgramInstance
        : crm === 'ALFACRM'
        ? alfaInstance
        : instance;

    const res = await crmInstance(path, {
      method,
      params,
      data
    })
      .then(response => response.data)
      .then(res => {
        if (res.success) {
          return res.data || res;
        }
        if (res.error && res.error !== 'timeout') {
          notify({
            title: t`Server error`,
            text: res.error_text || res.error
          });
          return {};
        }
        return res;
      })
      .catch(function (error) {
        notify({
          title: error.response.status || error.name || t`Server error`,
          text: error.response.data.error || error.message
        });
      });

    return res || {};
  };

  const dadataRequest = async ({path, data}) => {
    const res = await dadataInstance(path, {data: {count: 20, ...data}});
    return res.data.suggestions;
  };

  return {request, crmRequest, dadataRequest};
};

export const useWithIcon = ref => {
  const [withIcon, setWithIcon] = useState();

  useEffect(() => {
    setWithIcon(!!ref.current.querySelector('.icon'));
  }, []);

  return withIcon;
};

export const useCopyToClipboard = () => {
  const {setNotificationList} = useAppContext();

  const pushNotification = title =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [{title}]
      })
    );

  const copyToClipboard = text =>
    navigator.clipboard
      .writeText(text)
      .then(() => pushNotification(t`Copied to the clipboard`))
      .catch(() => {
        const input = document.createElement('input');
        input.value = text;

        document.body.appendChild(input);

        input.focus();
        input.select();

        const success = document.execCommand('copy');

        document.body.removeChild(input);

        pushNotification(
          success
            ? t`Copied to the clipboard`
            : t`Failed to copy to the clipboard`
        );
      });

  return copyToClipboard;
};

export const useOnKeyDown = ({onEnter = () => {}, onEscape = () => {}}) => {
  const callback = ({keyCode}) => {
    switch (keyCode) {
      case 13:
        onEnter();
        break;
      case 27:
        onEscape();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', callback);
    return () => document.removeEventListener('keydown', callback);
  }, []);
};

export const useOnClickOutside = ({
  ref = {},
  exception = {},
  onClick = () => {}
}) => {
  const callback = e => {
    if (
      !ref.current ||
      ref.current.contains(e.target) ||
      (exception.current &&
        (exception.current === e.target ||
          exception.current.contains(e.target)))
    ) {
      return;
    }

    onClick(e);
  };

  useEffect(() => {
    document.addEventListener('mouseup', callback);
    return () => document.removeEventListener('mouseup', callback);
  }, []);
};

export const useWebSocket = () => {
  const client = useRef();

  const connectWebSocket = () => {
    try {
      client.current = new w3cwebsocket('wss://ws.whatcrm.net/socket');
      client.current.binaryType = 'arraybuffer';
      client.current.onopen = () => {};
      client.current.onclose = () => setTimeout(() => connectWebSocket(), 1000);
    } catch {
      //
    }
  };

  const listenWebSocket = async ({chat_key, onChange, onConnect}) => {
    if (client.current) {
      client.current.onmessage = async message => {
        try {
          const messageData = JSON.parse(message.data);

          if (messageData.chat_key === chat_key && messageData.events) {
            const event = messageData.events[0];

            if (event.name === 'QR_CODE') {
              QRCode.toDataURL(event.code).then(qrCode =>
                onChange({state: 'got qr code', qrCode})
              );
            } else if (
              event.name === 'PENDING' ||
              event.name === 'NAVIGATION'
            ) {
              onChange({state: 'PENDING'});
            } else if (event.name === 'WAIT_PASSWORD') {
              onChange({
                passwordHint: event.authorizationStateWaitPassword.passwordHint,
                state: 'authorizationStateWaitPassword'
              });
            } else if (event.name === 'WAIT_CODE') {
              onChange({state: 'authorizationStateWaitCode'});
            } else if (event.name === 'LOADING_SCREEN') {
              onChange({state: 'LOADING_SCREEN', percent: event.percent});
            } else if (event.name === 'READY' || event.name === 'CONNECTED') {
              onConnect(event.info);
            }
          }
        } catch {
          //
        }
      };
    }
  };

  return {connectWebSocket, listenWebSocket};
};

export const useGetTariffName = () => {
  const {lang} = useAppContext();

  const isSlavic = lang === 'ru' || lang === 'uk';

  const getTariffName = tariff => {
    const quantity = tariff.quantity || 30;
    const period = tariff.period || 'day';

    return (
      <>
        {quantity}
        {' '}

        {isSlavic
          ? inflectNumberal({
              value: quantity,
              labels:
                lang === 'ru'
                  ? period === 'day'
                    ? ['день', 'дня', 'дней']
                    : ['месяц', 'месяца', 'месяцев']
                  : lang === 'uk' &&
                    (period === 'day'
                      ? ['день', 'дні', 'днів']
                      : ['місяць', 'місяці', 'місяців'])
            })
          : period === 'day'
          ? t`days`
          : t`months`}
      </>
    );
  };

  return getTariffName;
};

export const useGetTariffPlan = () => {
  const {lang} = useAppContext();

  const getTariffPlan = ({id, quantity}) => (
    <>
      {id === 3 ? 'Enterprise' : id === 2 ? 'Pro' : 'Start'}

      {quantity > 1 && (
        <>
          {' '}
          {c('quantity').t`for`} {quantity}
          {' '}
          {lang === 'ru'
            ? 'подключений'
            : lang === 'uk'
            ? 'підключень'
            : t`connections`}
        </>
      )}
    </>
  );

  return getTariffPlan;
};