import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Article = ({children, isWide, style}) => (
  <div
    className={cn('article', {
      article_wide: isWide
    })}
    style={style}
  >
    {children}
  </div>
);

Article.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
};

Article.defaultProps = {
  children: <></>,
  style: {}
};

export default Article;
