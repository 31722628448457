import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import Actions from './actions/actions.jsx';
import EmployeeList from './employee-list/employee-list.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import StaffContext from './staff-context.js';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {getEmployeeListReq} from '../../../../common/api/api.js';
import {filterArray} from '../../../../common/actions.js';

const Staff = () => {
  const {user} = useAppContext();

  const [employeeList, setEmployeeList] = useState();
  const [visibleEmployeeList, setVisibleEmployeeList] = useState();
  const [search, setSearch] = useState('');

  const {request} = useRequest();

  const getEmployeeList = async () => {
    const res = await request(
      getEmployeeListReq(_.pick(user, ['crm', 'domain']))
    );

    setEmployeeList(Object.values(res));
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    setVisibleEmployeeList(
      search
        ? filterArray(search, employeeList, ['fullname', 'email', 'phone'])
        : employeeList
    );
  }, [employeeList, search]);

  return (
    <StaffContext.Provider
      value={{
        employeeList,
        visibleEmployeeList,
        search,
        setEmployeeList,
        setSearch
      }}
    >
      <div>
        <Actions />

        {visibleEmployeeList ? (
          visibleEmployeeList.length > 0 ? (
            <EmployeeList />
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </div>
    </StaffContext.Provider>
  );
};

export default Staff;
