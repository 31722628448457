import update from 'immutability-helper';

import {useConnectionListContext} from '../connection-list-context.js';
import {useConnectionContext} from './connection-context.js';
import useCreateConnection from '../create-connection.js';
import {useRequest} from '../../../common/hooks.js';
import {deleteConnectionReq} from '../../../common/api/api.js';

const useDeleteConnection = () => {
  const {connectionList, setConnectionList} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const createConnection = useCreateConnection();
  const {request} = useRequest();

  const deleteConnection = async remake => {
    const res = await request(deleteConnectionReq(connection.chat_key));

    if (res.success) {
      const index = connectionList.findIndex(item => item.id === connection.id);

      setConnectionList(
        update(connectionList, {
          $splice: [[index, 1]]
        })
      );

      if (remake) {
        createConnection(remake);
      }
    }

    return !!res.success;
  };

  return deleteConnection;
};

export default useDeleteConnection;
