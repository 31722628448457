import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import Button from '../../../button/button.jsx';
import Icons from '../../../icons/icons.jsx';

const Search = forwardRef(({isCollapsed, setIsCollapsed}, ref) => {
  const handleClick = () => {
    setIsCollapsed(false);

    ref.current.focus();
    const {selectionEnd} = ref.current;
    setTimeout(() => (ref.current.selectionStart = selectionEnd));
  };

  return isCollapsed ? (
    <Button
      color="transparent"
      onClick={handleClick}
    >
      <Icons.Search />
    </Button>
  ) : (
    <Icons.Search />
  );
});

Search.propTypes = {
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func
};

Search.defaultProps = {
  isCollapsed: false,
  setIsCollapsed: () => {}
};

export default Search;
