import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import User from './user/user.jsx';
import Table, {
  TableHead,
  TableBody,
  TableCell
} from '../../../../../components/table/table.jsx';
import Preloader from '../../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks.js';
import {getIntegratorClientListReq} from '../../../../../common/api/api.js';

const UserList = () => {
  const {user} = useAppContext();

  const [userList, setUserList] = useState();

  const {request} = useRequest();

  const getUserList = async () => {
    const res = await request(
      getIntegratorClientListReq({crm: user.crm, domain: user.domain})
    );

    if (Object.keys(res).length) {
      setUserList(res);
      return;
    }

    setUserList([]);
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      {userList ? (
        userList.length ? (
          <Table>
            <TableHead>
              <TableCell>{t`Date of connection`}</TableCell>
              <TableCell>{t`Domain`}</TableCell>
            </TableHead>

            <TableBody>
              {userList.map(item => (
                <User
                  key={item.id}
                  user={item}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default UserList;
