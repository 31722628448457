import React, {useState} from 'react';
import {t} from 'ttag';

import {Checkbox} from '../../../../../form/form.jsx';
import Button from '../../../../../button/button.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {usePaymentContext} from '../../../payment-context.js';
import {useTariffPlanContext} from '../../tariff-plan-context.js';

import Total from './total/total.jsx';
import Method from './method/method.jsx';

const Transition = () => {
  const {user, lang} = useAppContext();
  const {client} = useConnectionListContext();
  const {connection, connectionIdList, selectedTariffId} = usePaymentContext();
  const {isSelected} = useTariffPlanContext();

  const [isSubscription, setIsSubscription] = useState(true);
  const [isMethodOpen, setIsMethodOpen] = useState();

  const openPaymentForm = (method = 'stripe') => {
    const params = new URLSearchParams({
      instance_id: Object.keys(connection).length
          ? [connection.id]
          : connectionIdList,
      type: client.crm,
      domain: client.domain,
      tariff_id: selectedTariffId,
      is_subscribed: isSubscription ? 1 : 0,
      redirect: window.location.href,
      ...(connectionIdList.length > 1
          ? {quantity: connectionIdList.length}
          : {}),
      ...(client.isCustomer ? {partner_ref: user.id} : {})
    });

    const src = `${process.env.REACT_APP_API}/payments/${method}?${params}`;
    window.open(src, '_self');
  };

  const isCloudpaymentsShown = lang === 'ru' && user.country !== 'UA';

  const isTelphinShown =
      !client.isCustomer && (user.telphin_code || lang === 'ru');

  const handleClick = () => {
    if (isCloudpaymentsShown || isTelphinShown) {
      setIsMethodOpen(true);
      return;
    }

    openPaymentForm();
  };

  return (
      <div>
        {connectionIdList.length > 1 && <Total />}

        <Checkbox
            label={t`Subscribe`}
            isChecked={isSubscription}
            isDisabled={!isSelected}
            style={{marginBottom: 16}}
            onChange={setIsSubscription}
        />

        <Button
            isDisabled={!isSelected}
            onClick={handleClick}
        >
          {t`Pay`}
        </Button>

        {isMethodOpen && (
            <Method
                isCloudpaymentsShown={isCloudpaymentsShown}
                isTelphinShown={isTelphinShown}
                openPaymentForm={openPaymentForm}
                onClick={() => setIsMethodOpen(false)}
            />
        )}
      </div>
  );
};

export default Transition;
