import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Toggle} from '../../../../../../form/form.jsx';

const IfContact = () => {
  const [field, , helpers] = useField('if_contact');

  return (
    <Toggle
      label={t`Receive messages only from existing contacts`}
      isChecked={field.value}
      onChange={helpers.setValue}
    />
  );
};

export default IfContact;
