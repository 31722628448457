import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';

import Popup from '../../../../components/popup/popup.jsx';
import * as Title from '../../../../components/title/title.jsx';
import Line from '../../../../components/line/line.jsx';
import Wrapper from '../../../../components/wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../../components/button/button.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {createDeniedReq} from '../../../../common/api/api.js';
import {isolateNumbers} from '../../../../common/actions.js';

import Phone from './phone/phone.jsx';

const Editor = ({setDenyList, onClose}) => {
  const {user} = useAppContext();
  const [type, setType] = useState('text');
  const {request} = useRequest();

  const handleSubmit = async ({phone}) => {
    const res = await request(
      createDeniedReq({
        crm: user.crm,
        domain: user.domain,
        phone:
          type === 'tel' ? isolateNumbers(phone) : phone.replaceAll('@', '')
      })
    );

    if (Object.keys(res).length) {
      setDenyList(prevValue =>
        update(prevValue, {
          $push: [res]
        })
      );

      onClose();
    }

    return true;
  };

  const initialValues = {phone: ''};

  const validationSchema = yup.object().shape({
    phone: yup.string().trim().required()
  });

  return (
    <Popup onClick={() => onClose()}>
      <Title.H2 style={{marginBottom: 16}}>
        {t`Add a phone number or username`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Phone
            type={type}
            setType={setType}
          />

          <Line />

          <Wrapper alignItems="center">
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={() => onClose()}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Editor.propTypes = {
  setDenyList: PropTypes.func,
  onClose: PropTypes.func
};

Editor.defaultProps = {
  setDenyList: () => {},
  onClose: () => {}
};

export default Editor;
