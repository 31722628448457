import React, {useRef, forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useWithIcon} from '../../common/hooks.js';

const Button = forwardRef(
  (
    {children, color, type, href, target, isLink, isDisabled, style, onClick},
    forwardedRef
  ) => {
    const innerRef = useRef();
    const ref = forwardedRef || innerRef;

    const withIcon = useWithIcon(ref);

    const params = {
      ref,
      className: cn('btn', {
        [`btn_${color}`]: color,
        'btn_with-icon': withIcon
      }),
      type,
      disabled: isDisabled,
      style,
      onClick
    };

    const rel = target === '_blank' && 'noreferrer';

    return (
      <>
        {isLink ? (
          <a
            {...params}
            href={href}
            target={target}
            rel={rel}
          >
            {children}
          </a>
        ) : (
          <button {...params}>{children}</button>
        )}
      </>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['blue', 'orange', 'white', 'transparent']),
  type: PropTypes.oneOf(['button', 'submit']),
  href: PropTypes.string,
  target: PropTypes.string,
  isLink: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
  children: <></>,
  color: 'blue',
  type: 'button',
  href: '',
  target: '_self',
  isLink: false,
  isDisabled: false,
  style: {},
  onClick: () => {}
};

export {default as SaveButton} from './save-button/save-button.jsx';
export default Button;
