import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {TableRow, TableCell} from '../../../../../components/table/table.jsx';
import Link from '../../../../../components/link/link.jsx';
import LocaleString from '../../../../../components/locale-string/locale-string.jsx';
import {useAppContext} from '../../../../../app-context.js';

import {
    useGetTariffName,
    useGetTariffPlan
} from '../../../../../common/hooks.js';

const PaymentRecord = ({paymentRecord, isRefund}) => {
    const {user} = useAppContext();

    const getTariffName = useGetTariffName();
    const getTariffPlan = useGetTariffPlan();

    const date = moment.unix(paymentRecord.date_add).utcOffset('+0300');
    const currency = paymentRecord.currency || 'RUB';
    const invoiceStatuses = ['Ожидает оплаты', 'Оплачен', 'Возврат', 'Отменен'];
    paymentRecord.quantity = paymentRecord.quantity || 1;
    paymentRecord.sum = paymentRecord.summa / 100;
    paymentRecord.linkTinkoff = "https://api.chitoff.ru/payments/tinkoff?guid=" + paymentRecord.guid;
    paymentRecord.linkInvoice = "https://api.chitoff.ru/payments/invoices?guid=" + paymentRecord.guid;
    return (
        <TableRow>
            <TableCell>
                {date.format('L')}, {date.format('LT')}
            </TableCell>
            <TableCell>
                <LocaleString
                    type="currency"
                    amount={paymentRecord.sum}
                    currency={currency}
                />
            </TableCell>
            <TableCell>
                {getTariffName({
                    quantity: paymentRecord.tariff_quantity,
                    period: paymentRecord.tariff_period
                })}
            </TableCell>

            <TableCell>
                {getTariffPlan({
                    id: paymentRecord.tariff_packet,
                    quantity: paymentRecord.quantity
                })}
            </TableCell>
            <TableCell>
                {invoiceStatuses[paymentRecord.status]}
                {paymentRecord.status == 0 && (
                    <Link
                        href={paymentRecord.linkTinkoff}
                        target="_blank"
                        style={{marginStart: "5px"}}
                        title="Оплатить по карте"
                    >
                        💳
                    </Link>
                )}
                {user.telphin_code && paymentRecord.status == 0 && (
                    <Link
                        href={paymentRecord.linkInvoice}
                        target="_blank"
                        title="Оплатить по cчету"
                    >
                        🧾
                    </Link>
                )}

            </TableCell>
        </TableRow>
    );
};

PaymentRecord.propTypes = {
    paymentRecord: PropTypes.shape({
        currency: PropTypes.string,
        date_add: PropTypes.string,
        domain: PropTypes.string,
        status: PropTypes.string,
        guid: PropTypes.string,
        partner_id: PropTypes.string,
        summa: PropTypes.string,
        tariff_packet: PropTypes.string,
        tariff_period: PropTypes.string,
        tariff_quantity: PropTypes.string
    }),
    isRefund: PropTypes.bool
};

PaymentRecord.defaultProps = {
    paymentRecord: {},
    isRefund: false
};

export default PaymentRecord;
