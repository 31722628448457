import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Label from './label/label.jsx';
import Version from './version/version.jsx';
import Popup from '../../../../popup/popup.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import Line from '../../../../line/line.jsx';
import Button, {SaveButton} from '../../../../button/button.jsx';

const Config = ({onCreate, onClose}) => {
  const initalValues = {label: '', version: 'whatsapp'};

  const validationSchema = yup.object().shape({
    label: yup.string(),
    version: yup.string()
  });

  return (
    <Popup onClick={onClose}>
      <Formik
        initialValues={initalValues}
        validationSchema={validationSchema}
        onSubmit={onCreate}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <Label />
            <Version />
          </Wrapper>

          <Line />
          <Wrapper>
            <SaveButton>{t`Create`}</SaveButton>

            <Button
              color="white"
              onClick={onClose}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Config.propTypes = {
  onCreate: PropTypes.func,
  onClose: PropTypes.func
};

Config.defaultProps = {
  onCreate: () => {},
  onClose: () => {}
};

export default Config;
