import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Text from '../../../../text/text.jsx';
import OptionList from '../option-list/option-list.jsx';
import {optionsPropTypes} from '../prop-types.js';

const GroupList = ({groupList, options}) => (
  <>
    {groupList.map((group, i) => (
      <div key={i}>
        <div
          className={cn('select__options-group', {
            'select__options-group_first': !i
          })}
        >
          <Text isSpan>{group}</Text>
        </div>

        <OptionList options={options.filter(item => item.group === group)} />
      </div>
    ))}
  </>
);

GroupList.propTypes = {
  groupList: PropTypes.arrayOf(PropTypes.string),
  options: optionsPropTypes
};

GroupList.defaultProps = {
  groupList: [],
  options: optionsPropTypes
};

export default GroupList;
