import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import Button from '../../../button/button.jsx';
import Icons from '../../../icons/icons.jsx';

const Eye = forwardRef(({htmlType, setHtmlType}, ref) => {
  const handleClick = () => {
    setHtmlType(htmlType === 'password' ? 'text' : 'password');

    ref.current.focus();
    const {selectionEnd} = ref.current;

    setTimeout(() => (ref.current.selectionStart = selectionEnd));
  };

  return (
    <Button
      color="transparent"
      onClick={handleClick}
    >
      {htmlType === 'password' ? <Icons.OpenEye /> : <Icons.ClosedEye />}
    </Button>
  );
});

Eye.propTypes = {
  htmlType: PropTypes.string,
  setHtmlType: PropTypes.func
};

Eye.defaultProps = {
  htmlType: PropTypes.string,
  setHtmlType: PropTypes.func
};

export default Eye;
