import React from 'react';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Email from './email/email.jsx';
import * as Title from '../../../components/title/title.jsx';
import Text from '../../../components/text/text.jsx';
import Line from '../../../components/line/line.jsx';
import {SaveButton} from '../../../components/button/button.jsx';
import {useLoginContext} from '../login-context.js';
import {useRequest} from '../../../common/hooks.js';
import {checkIsAccountExistReq} from '../../../common/api/api.js';

const Start = () => {
  const {setEmail, setIsRegistered} = useLoginContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const email = values.email.toLowerCase().trim();

    const {integration} = await request(
      checkIsAccountExistReq({
        crm: 'LK',
        domain: email
      })
    );

    setEmail(email);
    setIsRegistered(!!integration);

    return true;
  };

  const initialValues = {email: ''};

  const validationSchema = yup.object().shape({
    email: yup.string().email().required()
  });

  return (
    <>
      <Title.H2 style={{marginBottom: 8}}>{t`Sign in to Chitoff`}</Title.H2>

      <Text style={{marginBottom: 16}}>
        {t`Sign in to your account or create a new one.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Email />
          <Line />
          <SaveButton>{t`Continue`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

export default Start;
