import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Wrapper from '../../../wrapper/wrapper.jsx';
import Icons from '../../../icons/icons.jsx';
import Label from './label/label.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {updateConnectionReq} from '../../../../common/api/api.js';

const Name = ({style}) => {
  const {setNotificationList} = useAppContext();
  const {connectionList, setConnectionList} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const ref = useRef();

  const {request} = useRequest();

  const handleSubmit = async (values, helpers) => {
    const label = values.label.trim();
    ref.current.blur();

    if (connection.label !== label) {
      const res = await request(
        updateConnectionReq({
          chat_key: connection.chat_key,
          data: {label}
        })
      );

      const index = connectionList.findIndex(item => item.id === connection.id);

      if (res.label) {
        setConnectionList(prevValue =>
          update(prevValue, {
            [index]: {
              label: {$set: res.label}
            }
          })
        );

        setNotificationList(prevValue =>
          update(prevValue, {
            $push: [
              {title: res.label, text: t`The connection has been renamed.`}
            ]
          })
        );
      }
    }

    helpers.setValues({label: label || t`Connection ${connection.id}`});
    return true;
  };

  const initialValues = {
    label: connection.label || t`Connection ${connection.id}`
  };

  const validationSchema = yup.object().shape({
    label: yup.string()
  });

  return (
    <Wrapper
      alignItems="center"
      gap={4}
      noWrap
      style={style}
    >
      {connection.version === 'telegram' ? (
        <Icons.Telegram />
      ) : (
        connection.version === 'whatsapp' && <Icons.WhatsApp />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Label ref={ref} />
        </Form>
      </Formik>
    </Wrapper>
  );
};

Name.propTypes = {
  style: PropTypes.object
};

Name.defaultProps = {
  style: {}
};

export default Name;
