import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Select from '../components/select/select.jsx';
import Field from '../components/field/field.jsx';
import GroupList from './group-list/group-list.jsx';
import OptionList from './option-list/option-list.jsx';
import Dropdown from '../components/dropdown/dropdown.jsx';
import {useOnClickOutside, useOnKeyDown} from '../../../../common/hooks.js';
import SingleSelectContext from './single-select-context.js';
import {isIncludes} from '../../../../common/actions.js';
import {optionsPropTypes} from './prop-types.js';

const SingleSelect = ({
  options,
  value,
  placeholder,
  isDisabled,
  isMin,
  isFullWidth,
  isPreviewBold,
  isSearchAvailable,
  style,
  onChange,
  onClick
}) => {
  const [matchingOptionIds, setMatchingOptionIds] = useState([]);
  const [isOpen, setIsOpen] = useState();

  const ref = useRef();
  const fieldRef = useRef();

  useOnClickOutside({ref, onClick: () => setIsOpen(false)});
  useOnKeyDown({onEscape: () => setIsOpen(false)});

  const handleClick = () => {
    onClick();

    if (!isDisabled) {
      setIsOpen(!isOpen);
      setMatchingOptionIds([]);
    }
  };

  const handleSearch = searchQuery =>
    setMatchingOptionIds(
      options.reduce((acc, item) => {
        if (isIncludes(item.label, searchQuery)) {
          acc.push(item.value);
        }

        return acc;
      }, [])
    );

  const handleChange = value => {
    setIsOpen(false);
    onChange(value);
  };

  const groupList = options.reduce((acc, item) => {
    if (item.group && !acc.includes(item.group)) {
      acc.push(item.group);
    }

    return acc;
  }, []);

  const selected = options.find(item => item.value === value);

  const preview = selected ? selected.preview || selected.label : null;

  return (
    <Select
      ref={ref}
      style={style}
    >
      <Field
        ref={fieldRef}
        preview={preview}
        placeholder={placeholder}
        isPreviewBold={isPreviewBold}
        isOpen={isOpen}
        isDisabled={isDisabled}
        isMin={isMin}
        onClick={handleClick}
      />

      {isOpen && (
        <Dropdown
          fieldRef={fieldRef}
          isFullWidth={isFullWidth}
          isSearchAvailable={isSearchAvailable}
          onSearch={handleSearch}
        >
          <SingleSelectContext.Provider
            value={{value, matchingOptionIds, handleChange}}
          >
            {groupList.length > 0 ? (
              <GroupList
                groupList={groupList}
                options={options}
              />
            ) : (
              <OptionList options={options} />
            )}
          </SingleSelectContext.Provider>
        </Dropdown>
      )}
    </Select>
  );
};

SingleSelect.propTypes = {
  options: optionsPropTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  inMin: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isPreviewBold: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

SingleSelect.defaultProps = {
  options: [],
  value: '',
  placeholder: '',
  isDisabled: false,
  inMin: false,
  isFullWidth: false,
  isPreviewBold: false,
  style: {},
  onChange: () => {},
  onClick: () => {}
};

export default SingleSelect;
