import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import Text from '../../../../../../text/text.jsx';
import {SingleSelect} from '../../../../../../form/form.jsx';
import {useConnectionListContext} from '../../../../../connection-list-context.js';

const LineId = () => {
  const {pipelineList} = useConnectionListContext();

  const [field, , helpers] = useField('pipeline_id');
  const {setValue} = helpers;

  const options = pipelineList
    ? pipelineList.map(item => ({
        value: item.id,
        label: item.name
      }))
    : [];

  return (
    <div>
      <Text isSmall style={{marginBottom: 4}}>{t`Филиал`}</Text>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Pipeline`}
        isSearchAvailable
        onChange={setValue}
      />
    </div>
  );
};

export default LineId;
