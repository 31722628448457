import React from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {t} from 'ttag';

import * as Title from '../title/title.jsx';
import Text from '../text/text.jsx';
import Line from '../line/line.jsx';
import {SaveButton} from '../button/button.jsx';
import {useRequest} from '../../common/hooks.js';
import {checkAuthenticationPassword} from '../../common/api/api.js';

import Password from './password/password.jsx';

const TelegramAuthentication = ({chat_key, connectionName, passwordHint}) => {
  const {request} = useRequest();

  const handleSubmit = async values => {
    const password = values.password.trim();
    request(checkAuthenticationPassword({chat_key, password}));
    return true;
  };

  const initialValues = {password: ''};

  const validationSchema = yup.object().shape({
    password: yup.string().trim().required()
  });

  return (
    <>
      {connectionName}
      <Title.H2 style={{marginBottom: 8}}>{t`Enter the password`}</Title.H2>

      <Text style={{marginBottom: 8}}>
        {t`You have Two-Step Verification enabled, so your account is protected with an additional password.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Password passwordHint={passwordHint} />
          <Line />
          <SaveButton>{t`Continue`}</SaveButton>
        </Form>
      </Formik>
    </>
  );
};

TelegramAuthentication.propTypes = {
  chat_key: PropTypes.string,
  connectionName: PropTypes.node,
  passwordHint: PropTypes.string
};

TelegramAuthentication.defaultProps = {
  chat_key: '',
  connectionName: <></>,
  passwordHint: ''
};

export default TelegramAuthentication;
