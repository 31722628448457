import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {
  getConnectionConfigReq as getAmocrmConnectionConfigReq
  // updateConnectionConfigReq as updateAmocrmConnectionConfigReq
} from '../../../../common/api/amo.js';
import {getConnectionConfigReq as getBitrix24ConnectionConfigReq} from '../../../../common/api/bitrix24.js';
import {getConnectionConfigReq as getAlfaConnectionConfigReq} from '../../../../common/api/alfacrm.js';
// import {trimValues} from '../../../../common/actions.js';

import Config from './config/config.jsx';
import Popup from './popup/popup.jsx';
import Alert from './alert/alert.jsx';
import SettingsContext from './settings-context.js';

const Settings = () => {
  const {client /* connectionRequiringPreset, setConnectionRequiringPreset */} =
    useConnectionListContext();

  const {connection, isSettingsPopupOpen, justCrm} = useConnectionContext();

  const [connectionConfig, setConnectionConfig] = useState();

  const [isClosable, setIsClosable] = useState(true);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const {crmRequest} = useRequest();

  const updateConnectionConfig = res => {
    let keys = [];
    switch (justCrm) {
      case 'bitrix24':
        keys = ['first_message', 'group', 'line_id', 'lines'];
        break;
      case 'amocrm':
        keys = [
          'closed',
          'default',
          'group',
          'if_contact',
          'pipeline_settings',
          'strategy'
        ];
        break;
      case 'alfacrm':
        keys = ['is_group', 'pipeline_id', 'lines'];
        break;
    }
    setConnectionConfig(_.pick(res, keys));

    if (Object.keys(res).length) {
      setIsClosable(false);
    }
  };

  const getConnectionConfig = async () => {
    let params = {};
    switch (justCrm) {
      case 'bitrix24':
        params = getBitrix24ConnectionConfigReq({
          domain: client.domain,
          chat_id: connection.id
        });
        break;
      case 'amocrm':
        params = getAmocrmConnectionConfigReq({
          domain: client.domain,
          phone: connection.phone
        });
        break;
      case 'alfacrm':
        params = getAlfaConnectionConfigReq({
          domain: client.domain,
          chat_key: connection.chat_key
        });
        break;
    }

    let crmName = client.crm;
    if (crmName === 'LK' && client.widget_code === 'ALFACRM') {
      crmName = 'ALFACRM';
    }
    const res = await crmRequest({
      crm: crmName,
      ...params
    });
    updateConnectionConfig(res);
  };

  useEffect(() => {
    if (
      connection.phone &&
      connectionConfig &&
      !Object.keys(connectionConfig).length
    ) {
      getConnectionConfig();
    }
  }, [connection.phone]);

  useEffect(() => {
    getConnectionConfig();
  }, []);

  // const presetConnectionConfig = async values => {
  //   const trimmedValues = trimValues(values);

  //   const res = await crmRequest({
  //     crm: client.crm,
  //     ...updateAmocrmConnectionConfigReq({
  //       domain: client.domain,
  //       phone: connection.phone,
  //       data: trimmedValues
  //     })
  //   });

  //   if (Object.keys(res).length > 3) {
  //     updateConnectionConfig(res);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     connectionRequiringPreset === connection.id &&
  //     justCrm === 'amocrm' &&
  //     connectionConfig &&
  //     pipelineList
  //   ) {
  //     presetConnectionConfig({
  //       ...connectionConfig,
  //       closed: false,
  //       pipeline_settings: pipelineList[0]?.id
  //         ? [
  //             {
  //               pipe_id: pipelineList[0].id,
  //               status_id: pipelineList[0]?.statuses?.[0]?.id | null,
  //               manager_id: null
  //             }
  //           ]
  //         : []
  //     });

  //     setConnectionRequiringPreset(-1);
  //   }
  // }, [connection.phone]);

  return (
    <SettingsContext.Provider
      value={{connectionConfig, updateConnectionConfig, setIsAlertOpen}}
    >
      <Config />
      {isSettingsPopupOpen && <Popup isClosable={isClosable} />}
      {isAlertOpen && <Alert />}
    </SettingsContext.Provider>
  );
};

export default Settings;
