import React from 'react';
import {useEditorContext} from '../editor-context.js';
import Attachment from './attachment/attachment.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import Preloader from '../../../../../components/preloader/preloader.jsx';

const AttachmentList = () => {
  const {messageTemplate, attachmentList} = useEditorContext();

  const isShown = attachmentList.length > 0 || messageTemplate.file_count > 0;

  return (
    <>
      {isShown &&
        (attachmentList.length > 0 ? (
          <Wrapper
            gap={4}
            isColumn
          >
            {attachmentList.map((item, i) => (
              <Attachment
                key={i}
                attachment={item}
                index={i}
              />
            ))}
          </Wrapper>
        ) : (
          <Preloader />
        ))}
    </>
  );
};

export default AttachmentList;
