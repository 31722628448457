import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import Button from '../button/button.jsx';
import Icons from '../icons/icons.jsx';
import {useOnClickOutside, useOnKeyDown} from '../../common/hooks.js';
import {definePosition} from '../../common/actions.js';

const Tooltip = ({children, type, style}) => {
  const [isShown, setIsShown] = useState(false);
  const [position, setPosition] = useState();

  const triggerRef = useRef();
  const containerRef = useRef();
  const targetRef = useRef();

  useOnClickOutside({ref: targetRef, onClick: () => setIsShown(false)});
  useOnKeyDown({onEscape: () => setIsShown(false)});

  const handleClick = () => {
    if (isShown) {
      setIsShown(false);
      return;
    }

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const targetRect = targetRef.current.getBoundingClientRect();

    const res = definePosition({
      triggerPosition: {
        x: triggerRect.x,
        y: triggerRect.bottom
      },
      startingPoint: _.pick(containerRect, ['x', 'y']),
      targetSize: _.pick(targetRect, ['width', 'height'])
    });

    setPosition(res);
    setIsShown(true);
  };

  return (
    <div className="tooltip">
      <Button
        ref={triggerRef}
        color="transparent"
        style={style}
        onClick={handleClick}
      >
        {type === 'warning' ? <Icons.Warning /> : <Icons.Tooltip />}
      </Button>

      <div
        ref={containerRef}
        className="tooltip__container"
      >
        <div
          ref={targetRef}
          className={cn('tooltip__wrapper', {
            tooltip__wrapper_shown: isShown
          })}
          style={position}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  style: PropTypes.object
};

Tooltip.defaultProps = {
  children: <></>,
  type: '',
  style: {}
};

export default Tooltip;
