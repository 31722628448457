import React from 'react';
import {t} from 'ttag';
import * as Title from '../../../../../../title/title.jsx';
import LocaleString from '../../../../../../locale-string/locale-string.jsx';
import {useAppContext} from '../../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../../connection-list-context.js';
import {usePaymentContext} from '../../../../payment-context.js';
import {useTariffPlanContext} from '../../../tariff-plan-context.js';
import {inflectNumberal} from '../../../../../../../common/actions.js';

const Total = () => {
  const {user, lang} = useAppContext();
  const {client} = useConnectionListContext();
  const {selectedTariffId, connectionIdList} = usePaymentContext();
  const {tariffOptions, isSelected} = useTariffPlanContext();

  const isDiscount = user.is_review === 2 && !client.isCustomer;

  const selectedTariff =
    tariffOptions.find(item => item.id === selectedTariffId) || {};

  const amount =
    (isDiscount ? selectedTariff.summa_review : selectedTariff.summa) *
    connectionIdList.length;

  return (
    <Title.H2
      className={isSelected ? '' : 'title_hidden'}
      style={{marginBottom: 16}}
    >
      {t`Total`}
      {': '}
      <LocaleString
        amount={amount}
        type="currency"
        currency={selectedTariff.currency}
      />{' '}
      {t`for`}
      {' '}
      {connectionIdList.length}
      {' '}
      {lang === 'ru'
        ? inflectNumberal({
            value: connectionIdList.length,
            labels: ['подключение', 'подключения', 'подключений']
          })
        : lang === 'uk'
        ? inflectNumberal({
            value: connectionIdList.length,
            labels: ['підключення', 'підключення', 'підключень']
          })
        : t`connections`}
    </Title.H2>
  );
};

export default Total;
