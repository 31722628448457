import React, {useState} from 'react';
import _ from 'lodash';
import {t} from 'ttag';
import Staff from './staff/staff.jsx';
import PaymentHistory from './payment-history/payment-history.jsx';
import ConnectionList from '../../../components/connection-list/connection-list.jsx';
//import CommunicationList from '../../../components/communication-list/communication-list.jsx';
import Section from '../../../components/section/section.jsx';
import {NavTab} from '../../../components/nav/nav.jsx';
import {useAppContext} from '../../../app-context.js';
import {useHomeContext} from '../home-context.js';

const Connections = () => {
  const {user} = useAppContext();
  const {connectionList, setConnectionList} = useHomeContext();

  const [tab, setTab] = useState('connectionList');

  const options = [
    {
      value: 'connectionList',
      label: t`My connections`
    },
    {
      value: 'staff',
      label: t`Connection access`
    },
    {
      value: 'payment-history',
      label: t`Payment history`
    },
  ];

  const navParams = {
    options: options,
    value: tab,
    onChange: setTab
  };

  return (
    <Section
      title={t`Connections`}
      navParams={navParams}
      isDefaultOpen
    >
      <NavTab tab="connectionList">
        <ConnectionList
          connectionList={connectionList}
          client={_.pick(user, ['crm', 'domain', 'widget_code', 'is_integration'])}
          setConnectionList={setConnectionList}
        />
      </NavTab>

      <NavTab tab="staff">
        <Staff />
      </NavTab>

      <NavTab tab="payment-history">
        <PaymentHistory />
      </NavTab>
    </Section>
  );
};

export default Connections;
