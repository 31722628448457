import React from 'react';
import {useField} from 'formik';
import ReactCodeInput from 'react-code-input';

const Code = () => {
  const [field, meta, helpers] = useField('code');

  const {value} = field;
  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <ReactCodeInput
      className=" "
      value={value}
      fields={8}
      isValid={isValid}
      inputStyleInvalid={{borderColor: '#ff8242'}}
      onChange={setValue}
    />
  );
};

export default Code;
