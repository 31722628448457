import React, {useState} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import Editor from '../../editor/editor.jsx';
import Button from '../../../../../../components/button/button.jsx';
import Article from '../../../../../../components/article/article.jsx';
import Text from '../../../../../../components/text/text.jsx';
import Icons from '../../../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useStaffContext} from '../../staff-context.js';
import {useRequest} from '../../../../../../common/hooks.js';
import {createEmployeeReq} from '../../../../../../common/api/api.js';
import {trimValues} from '../../../../../../common/actions.js';

const Creating = () => {
  const {user} = useAppContext();
  const {setEmployeeList} = useStaffContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    setIsOpen(false);

    const res = await request(
      createEmployeeReq({
        crm: user.crm,
        domain: user.domain,
        data: trimmedValues
      })
    );

    if (res.id) {
      setEmployeeList(prevValue =>
        update(prevValue, {
          $push: [res]
        })
      );
    }

    return true;
  };

  return (
    <>
      {user.crm === 'LK' ? (
        <Button onClick={() => setIsOpen(true)}>
          <Icons.Plus />
          {t`Add an employee`}
        </Button>
      ) : (
        <Article>
          <Text
            color="dark"
            isBold
          >
            {t`You can add employees in the settings of your CRM.`}
          </Text>
        </Article>
      )}

      {isOpen && (
        <Editor
          title={t`Add an employee`}
          onSubmit={handleSubmit}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Creating;
