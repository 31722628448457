import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../../components/title/title.jsx';
import {MultiSelect} from '../../../../../../components/form/form.jsx';
import {useHomeContext} from '../../../../home-context.js';

const AccessInstances = () => {
  const {connectionList} = useHomeContext();

  const [field, , helpers] = useField('access_instances');

  const handleChange = temp => {
    const idList = temp.reduce((acc, item) => {
      if (item.isChecked) {
        acc.push(item.value);
      }

      return acc;
    }, []);

    helpers.setValue(`[${idList.length ? idList.join(', ') : -1}]`);
  };

  const availableConnectionList = field.value
    ? field.value
        .substring(1, field.value.length - 1)
        .split(', ')
        .map(item => parseInt(item))
        .filter(item =>
          connectionList.find(connection => connection.id === item)
        )
    : [];

  const options = connectionList.map(item => ({
    value: item.id,
    label: item.label || `${t`Connection`} ${item.id}`,
    isChecked: availableConnectionList.length
      ? availableConnectionList.includes(item.id)
      : !field.value.length
  }));

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Available connections`}</Title.H3>

      <MultiSelect
        options={options}
        placeholder={t`Available connections`}
        isSearchAvailable
        onChange={handleChange}
      />
    </>
  );
};

export default AccessInstances;
