import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Connector from './pages/connector/connector.jsx';
import Login from './pages/login/login.jsx';
import Scanner from './pages/scanner/scanner.jsx';
import Home from './pages/home/home.jsx';
import Notices from './pages/notices/notices.jsx';
import Error404 from './pages/error-404/error-404.jsx';
import Header from './components/header/header.jsx';
import Footer from './components/footer/footer.jsx';
import {useAppContext} from './app-context.js';

const Router = () => {
  const {user} = useAppContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/connector/:chat_id"
          element={<Connector />}
        />

        <Route
          path="*"
          element={
            <>
              <Header />

              <div className="body">
                <Routes>
                  <Route
                    path="/login"
                    element={<Login />}
                  />

                  <Route
                    path="/scanner/:chat_key"
                    element={<Scanner />}
                  />

                  <Route
                    path="*"
                    element={
                      user.domain ? (
                        <Routes>
                          <Route
                            path="/"
                            element={<Home />}
                          />

                          <Route
                            path="/notices/:id"
                            element={<Notices />}
                          />

                          <Route
                            path="*"
                            element={<Error404 />}
                          />
                        </Routes>
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                </Routes>
              </div>

              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
