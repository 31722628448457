import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {t} from 'ttag';
import Popup from '../../../../../../popup/popup.jsx';
import * as Title from '../../../../../../title/title.jsx';
import Image from '../../../../../../image/image.jsx';
import Text from '../../../../../../text/text.jsx';
import Link from '../../../../../../link/link.jsx';
import Line from '../../../../../../line/line.jsx';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import Button from '../../../../../../button/button.jsx';

import {useAppContext} from '../../../../../../../app-context.js';
import {usePaymentContext} from '../../../../payment-context.js';

const Method = ({
                    isCloudpaymentsShown,
                    isTelphinShown,
                    openPaymentForm,
                    onClick
                }) => {
    const {user, setIsTelphinClientFormOpen} = useAppContext();
    const {closePayment} = usePaymentContext();

    const [method, setMethod] = useState();

    const handleClick = () => {
        closePayment();
        setIsTelphinClientFormOpen(true);
    };

    return (
        <Popup onClick={onClick}>
            <Title.H1 style={{marginBottom: 24}}>
                {t`Choose a payment method`}
            </Title.H1>

            <Wrapper
                gap={16}
                isColumn
                style={{marginBottom: 56}}
            >
                {isCloudpaymentsShown && false && (
                    <div
                        className={cn('method', {
                            method_selected: method === 'cloud'
                        })}
                        onClick={() => setMethod('cloud')}
                    >
                        <Image
                            src="static/img/icons/wallet.svg"
                            style={{marginBottom: 24}}
                        />

                        <Title.H2>Российской картой онлайн</Title.H2>
                    </div>
                )}

                <div
                    className={cn('method', {
                        method_selected: method === 'tinkoff'
                    })}
                    onClick={() => setMethod('tinkoff')}
                >
                    <Image
                        src="static/img/icons/wallet.svg"
                        style={{marginBottom: 24}}
                    />

                    <Title.H2>{t`By card online`}</Title.H2>
                </div>

                {isTelphinShown && (
                    <>
                        <div
                            className={cn('method', {
                                method_selected: method === 'invoices',
                                method_disabled: !user.telphin_code
                            })}
                            onClick={() => setMethod('invoices')}
                        >
                            <Image
                                src="static/img/icons/telphin-cabinet.svg"
                                style={{marginBottom: 24}}
                            />

                            <Title.H2>Оплата по счету</Title.H2>
                        </div>

                        {!user.telphin_code && (
                            <Text>
                                Чтобы оплатить по счету необходимо{' '}
                                <Link
                                    isFake
                                    onClick={handleClick}
                                >
                                    заполнить реквизиты
                                </Link>
                                .
                            </Text>
                        )}
                    </>
                )}
            </Wrapper>

            <Line />

            <Wrapper alignItems="center">
                <Button
                    isDisabled={!method}
                    onClick={() => openPaymentForm(method)}
                >
                    {t`OK`}
                </Button>

                <Button
                    color="white"
                    onClick={onClick}
                >
                    {t`Cancel`}
                </Button>
            </Wrapper>
        </Popup>
    );
};

Method.propTypes = {
    isCloudpaymentMethodAvailable: PropTypes.bool,
    isTelphinMethodAvailable: PropTypes.bool,
    openPaymentForm: PropTypes.func,
    onClick: PropTypes.func
};

Method.defaultProps = {
    isCloudpaymentMethodAvailable: false,
    isTelphinMethodAvailable: false,
    openPaymentForm: () => {},
    onClick: () => {}
};

export default Method;
