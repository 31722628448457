import PropTypes from 'prop-types';
import {useAppContext} from '../../app-context.js';

const LocaleString = ({amount, type, currency}) => {
  const {lang} = useAppContext();

  return parseFloat(amount).toLocaleString(lang, {
    style: type,
    currency
  });
};

LocaleString.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.string,
  currency: PropTypes.string
};

LocaleString.defaultProps = {
  amount: 0,
  type: 'decimal',
  currency: 'RUB'
};

export default LocaleString;
