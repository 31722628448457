import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Closer from '../closer/closer.jsx';
import {useOnKeyDown} from '../../common/hooks.js';

const Popup = ({
  children,
  isClosable,
  isWide,
  isExtraWide,
  isMaxWide,
  withBackground,
  onClick
}) => {
  const ref = useRef();

  useEffect(() => {
    if (withBackground) {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = '');
    }
  }, []);

  useOnKeyDown({
    onEscape: () => {
      if (!ref.current.querySelector('.popup')) {
        onClick();
      }
    }
  });

  return (
    <div
      ref={ref}
      className={cn('popup', {
        popup_bg: withBackground
      })}
    >
      <div
        className={cn('popup__wrapper', {
          popup__wrapper_wide: isWide,
          'popup__wrapper_extra-wide': isExtraWide,
          'popup__wrapper_max-wide': isMaxWide
        })}
      >
        {isClosable && (
          <div className="position-relative">
            <Closer
              color="light-blue"
              style={
                isMaxWide
                  ? {
                      top: -32,
                      right: -32
                    }
                  : {
                      top: -16,
                      right: -16
                    }
              }
              onClick={onClick}
            />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  isClosable: PropTypes.bool,
  isWide: PropTypes.bool,
  isExtraWide: PropTypes.bool,
  isMaxWide: PropTypes.bool,
  withBackground: PropTypes.bool,
  onClick: PropTypes.func
};

Popup.defaultProps = {
  children: <></>,
  isClosable: true,
  isWide: false,
  isExtraWide: false,
  isMaxWide: false,
  withBackground: true,
  onClick: () => {}
};

export default Popup;
