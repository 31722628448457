import React from 'react';
import FeatureList from './feature-list/feature-list.jsx';
import OptionList from './option-list/option-list.jsx';
import Transition from './transition/transition.jsx';

const TariffBody = () => (
  <div className="tariff-plan__body">
    <FeatureList />
    <OptionList />
    <Transition />
  </div>
);

export default TariffBody;
