import React, {useEffect, useState, useRef} from 'react';
import {addLocale, useLocale} from 'ttag';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/uk';

import Router from './Router.js';
import NotificationList from './components/notification-list/notification-list.jsx';
import AppContext from './app-context.js';
import {getAppConfig, getFromLocalStorage} from './common/actions.js';

const App = () => {
    const appConfig = getAppConfig();
    const {lang, connector} = appConfig;

    const [user, setUser] = useState(appConfig.user);
    const [theme, setTheme] = useState();
    const [notificationList, setNotificationList] = useState([]);
    const [isForbidden, setIsForbidden] = useState(false);
    const [isTelphinClientFormOpen, setIsTelphinClientFormOpen] = useState(false);

    const isSystemThemeDark = useRef();

    useEffect(() => {
        const preset = getFromLocalStorage('theme') || 'system';
        setTheme(preset);

        if (navigator && 'serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js');
        }
    }, []);

    const updateTheme = isDark => {
        const body = document.getElementsByTagName('body')[0];
        body.className = isDark ? 'dark-theme' : '';
    };

    const handleSystemThemeChange = ({matches}) => {
        if (theme === 'system') {
            updateTheme(matches);
        }
    };

    useEffect(() => {
        isSystemThemeDark.current = window.matchMedia(
            '(prefers-color-scheme: dark)'
        );

        isSystemThemeDark.current.addEventListener(
            'change',
            handleSystemThemeChange
        );

        updateTheme(
            theme === 'dark' ||
            (theme === 'system' && isSystemThemeDark.current.matches)
        );

        return () =>
            isSystemThemeDark.current.removeEventListener(
                'change',
                handleSystemThemeChange
            );
    }, [theme]);

    if (['de', 'es', 'pt', 'ru', 'tr', 'uk'].includes(lang)) {
        // eslint-disable-next-line
        addLocale(lang, require(`./locale/${lang}.po.json`));
    }

    useLocale(lang);
    moment.locale(lang);

    const whatsAppSupport = 'https://wa.me/79952467898';

    const licenseAgreement = `https://chitoff.net/license-agreement`;

    return (
        <AppContext.Provider
            value={{
                user,
                theme,
                connector,
                notificationList,
                lang,
                whatsAppSupport,
                licenseAgreement,
                isForbidden,
                isTelphinClientFormOpen,
                setUser,
                setTheme,
                setNotificationList,
                setIsForbidden,
                setIsTelphinClientFormOpen
            }}
        >
            <div className="app">
                <Router />
                <NotificationList />
            </div>
        </AppContext.Provider>
    );
};

export default App;
