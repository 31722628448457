import React from 'react';

import Wrapper from '../../../../wrapper/wrapper.jsx';
import Text from '../../../../text/text.jsx';

const Label = ({text, number}) => (
  <Wrapper
    gap={3}
    noWrap
  >
    <Text
      color="dark"
      isOneLine
    >
      {text}
    </Text>

    <Text color="dark">({number})</Text>
  </Wrapper>
);

export default Label;
