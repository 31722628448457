import React from 'react';
import Item from './item/item.jsx';
import {useAppContext} from '../../app-context.js';

const NotificationList = () => {
  const {notificationList} = useAppContext();

  return (
    <div className="notification-list">
      {notificationList.map((item, i) => (
        <Item
          key={i}
          index={i}
          data={item}
        />
      ))}
    </div>
  );
};

export default NotificationList;
