import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {t} from 'ttag';
import Customer from './customer/customer.jsx';
import Creating from './creating/creating.jsx';
import Table, {
  TableHead,
  TableBody,
  TableCell
} from '../../../../components/table/table.jsx';
import Text from '../../../../components/text/text.jsx';
import Link from '../../../../components/link/link.jsx';
import Button from '../../../../components/button/button.jsx';
import Icons from '../../../../components/icons/icons.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import CustomerListContext from './customer-list-context.js';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {getCustomerListReq} from '../../../../common/api/api.js';

const CustomerList = () => {
  const {user, lang, whatsAppSupport} = useAppContext();

  const [customerList, setCustomerList] = useState();

  const [isCreatingOpen, setIsCreatingOpen] = useState(false);

  const {request} = useRequest();

  const getCustomerList = async () => {
    const res = await request(
      getCustomerListReq(_.pick(user, ['crm', 'domain']))
    );

    setCustomerList(Object.values(res));
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const amocrmIcon =
    lang === 'ru' ? '/static/img/crm/amocrm.svg' : '/static/img/crm/kommo.svg';

  const crmList = {
    LK: {
      name: t`Personal account`,
      icon: '/static/img/crm/whatcrm.svg'
    },
    B24: {
      name: `${t`Bitrix24`} WhatsApp`,
      icon: '/static/img/crm/bitrix24.svg'
    },
    TELPHGRAM: {
      name: `${t`Bitrix24`} Telegram`,
      icon: '/static/img/crm/bitrix24.svg'
    },
    AMO: {
      name: `${t`Kommo`} WhatsApp`,
      icon: amocrmIcon
    },
    TELEGRAM: {
      name: `${t`Kommo`} Telegram`,
      icon: amocrmIcon
    }
  };

  return (
    <CustomerListContext.Provider
      value={{
        customerList,
        crmList,
        setCustomerList
      }}
    >
      <div>
        <Text style={{marginBottom: 12}}>
          <Link
            href={whatsAppSupport}
            target="_blank"
          >
            {t`Write to the Partner Relationship Department`}
          </Link>
        </Text>

        <Button
          style={{marginBottom: 16}}
          onClick={() => setIsCreatingOpen(true)}
        >
          <Icons.Plus />
          {t`Add a customer`}
        </Button>

        {customerList ? (
          customerList.length > 0 ? (
            <Table>
              <TableHead>
                <TableCell>{t`Domain`}</TableCell>

                <TableCell>{t`Type`}</TableCell>

                <TableCell />
                <TableCell />
              </TableHead>

              <TableBody>
                {customerList.map(item => (
                  <Customer
                    key={item.id}
                    customer={item}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}

        {isCreatingOpen && (
          <Creating onClose={() => setIsCreatingOpen(false)} />
        )}
      </div>
    </CustomerListContext.Provider>
  );
};

export default CustomerList;
