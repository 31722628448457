import React from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import * as Title from '../../../../../components/title/title.jsx';
import Text from '../../../../../components/text/text.jsx';
import Link from '../../../../../components/link/link.jsx';
import Button from '../../../../../components/button/button.jsx';
import Icons from '../../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useIntegratorContext} from '../integrator-context.js';
import {useRequest, useCopyToClipboard} from '../../../../../common/hooks.js';
import {getIntegrationTokenReq} from '../../../../../common/api/api.js';

const Info = () => {
  const {user} = useAppContext();
  const {integrator, setIntegrator} = useIntegratorContext();

  const {request} = useRequest();
  const copyToClipboard = useCopyToClipboard();

  const handleClick = async () => {
    const {token} = await request(
      getIntegrationTokenReq({
        crm: user.crm,
        domain: user.domain
      })
    );

    if (token) {
      setIntegrator(
        update(integrator, {
          token: {
            token: {$set: token}
          }
        })
      );
    }
  };

  const status =
    integrator.is_registration === 0
      ? t`Moderation`
      : integrator.is_registration === 2
      ? t`Published`
      : t`Unknown`;

  const url = `${window.location.origin}/login?widget_code=${integrator.title}`;
  const pair = `"${integrator.token.name}": "${integrator.token.token}"`;

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Widget code`}</Title.H3>

      <Text style={{marginBottom: 16}}>{integrator.title}</Text>

      <Title.H3 style={{marginBottom: 8}}>{t`Status`}</Title.H3>

      <Text style={{marginBottom: 16}}>{status}</Text>

      <Title.H3
        style={{marginBottom: 8}}
      >{t`Registration link for new customers`}</Title.H3>

      <Wrapper
        alignItems="center"
        noWrap
        style={{marginBottom: 16}}
      >
        <Text
          color="dark"
          isDedicated
        >
          {url}
        </Text>

        <Button
          color="transparent"
          onClick={() => copyToClipboard(url)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>

      <Title.H3 style={{marginBottom: 8}}>{t`API Documentation`}</Title.H3>

      <Text style={{marginBottom: 16}}>
        <Link
          href="https://documenter.getpostman.com/view/16489057/TzzDLFp4"
          target="_blank"
        >
          {t`Read`}
        </Link>
      </Text>

      <Title.H3 style={{marginBottom: 8}}>{t`API key`}</Title.H3>

      {integrator.token.id === 0 ? (
        <Text>{t`There is no API key. Please contact support.`}</Text>
      ) : (
        <>
          <Wrapper
            alignItems="center"
            noWrap
            style={{marginBottom: 16}}
          >
            <Text
              color="dark"
              isDedicated
            >
              {pair}
            </Text>

            <Button
              color="transparent"
              onClick={() => copyToClipboard(pair)}
            >
              <Icons.Copy />
            </Button>
          </Wrapper>

          <Button onClick={handleClick}>{t`Refresh`}</Button>
        </>
      )}
    </>
  );
};

export default Info;
