import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Input} from '../../../../../../components/form/form.jsx';
import {isolateNumbers} from '../../../../../../common/actions.js';

const Code = () => {
  const [field, , helpers] = useField('code');

  const {setValue} = helpers;

  return (
    <Input
      {...field}
      type="tel"
      placeholder={t`Dialing code`}
      maxLength={15}
      isMin
      onChange={value => setValue(isolateNumbers(value))}
    />
  );
};

export default Code;
