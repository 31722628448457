import React from 'react';
import {t} from 'ttag';
import Text from '../../../../components/text/text.jsx';
import Line from '../../../../components/line/line.jsx';
import Button from '../../../../components/button/button.jsx';
import Icons from '../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useMenuContext} from '../menu-context.js';
const {version} = require('../../../../../package.json');

const Options = () => {
  const {user, isForbidden} = useAppContext();

  const {setIsOpen, setIsSettingsOpen, setIsConfirmationOpen} =
    useMenuContext();

  const openSettings = () => {
    setIsOpen(false);
    setIsSettingsOpen(true);
  };

  const openConfirmation = () => {
    setIsOpen(false);
    setIsConfirmationOpen(true);
  };

  return (
    <div className="menu-options">
      <Text
        color="dark"
        isOneLine
      >
        {user.domain}
      </Text>

      <Line
        clearance={12}
        style={{margin: '12px 0 4px'}}
      />

      <div style={{marginBottom: 4}}>
        {!isForbidden && (
          <Button
            color="transparent"
            onClick={openSettings}
          >
            <Icons.Gear className="icon" />
            {t`Settings`}
          </Button>
        )}

        {user.crm === 'LK' && (
          <Button
            color="transparent"
            onClick={openConfirmation}
          >
            <Icons.SignOut className="icon" />
            {t`Sign out`}
          </Button>
        )}
      </div>

      <Text
        isSmall
        style={{textAlign: 'center'}}
      >
        {version}
      </Text>
    </div>
  );
};

export default Options;
