import React from 'react';
import update from 'immutability-helper';
import {Dropzone} from '../../../../../components/form/form.jsx';
import {useEditorContext} from '../editor-context.js';

const AttachMenu = () => {
  const {setAttachmentList} = useEditorContext();

  const handleChange = async fileList => {
    for (const item of fileList) {
      if (
        (item.type.includes('image') && item.size <= 5242880) ||
        (!item.type.includes('image') && item.size <= 20971520)
      ) {
        setAttachmentList(prevValue =>
          update(prevValue, {
            $push: [
              {
                file: item,
                title: item.name,
                type: item.type
              }
            ]
          })
        );
      }
    }
  };

  return (
    <>
      <Dropzone
        multiple
        isMax
        style={{marginBottom: 16}}
        onChange={handleChange}
      />
    </>
  );
};

export default AttachMenu;
