import React, {useState, useEffect} from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Code from './code/code.jsx';
import Article from '../../../../../components/article/article.jsx';
import * as Title from '../../../../../components/title/title.jsx';
import Text from '../../../../../components/text/text.jsx';
import Line from '../../../../line/line.jsx';
import {SaveButton} from '../../../../../components/button/button.jsx';
import Preloader from '../../../../../components/preloader/preloader.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks.js';
import {
  getDialingCodeReq,
  updateDialingCodeReq,
  deleteDialingCodeReq
} from '../../../../../common/api/bitrix24.js';

const Bitrix24 = () => {
  const {user, setNotificationList} = useAppContext();

  const [dialingCode, setDialingCode] = useState();

  const {crmRequest} = useRequest();

  const getDialingCode = async () => {
    const res = await crmRequest({
      crm: user.crm,
      ...getDialingCodeReq(user.domain)
    });

    setDialingCode(res);
  };

  useEffect(() => {
    getDialingCode();
  }, []);

  const handleSubmit = async values => {
    const code = values.code.trim();

    const res = await crmRequest({
      crm: user.crm,
      ...(code
        ? updateDialingCodeReq({domain: user.domain, code})
        : deleteDialingCodeReq(user.domain))
    });

    if (typeof res === 'string') {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: res.length
                ? t`The dialing code has been updated`
                : t`The dialing code has been removed`
            }
          ]
        })
      );
    }

    return true;
  };

  const initialValues = {
    code: typeof dialingCode === 'string' ? dialingCode : ''
  };

  const validationSchema = yup.object().shape({code: yup.string()});

  return (
    <>
      <Article style={{marginBottom: 16}}>
        <Title.H3 style={{marginBottom: 8}}>{t`Dialing code`}</Title.H3>

        <Text>
          {t`If it is not listed in the phone number, it will be substituted when you send a message.`}
        </Text>
      </Article>

      {dialingCode ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Code />
            <Line />
            <SaveButton>{t`Save`}</SaveButton>
          </Form>
        </Formik>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Bitrix24;
