import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import _ from 'lodash';
import Page from '../../components/page/page.jsx';
import ConnectionList from '../../components/connection-list/connection-list.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest} from '../../common/hooks.js';
import {getConnectionListReq} from '../../common/api/api.js';
import {updateConnectionConfigReq} from '../../common/api/bitrix24.js';
import {getConnectionCondition} from '../../common/actions.js';

const Connector = () => {
  const {chat_id} = useParams();

  const {line_id} = Object.fromEntries(
      new URLSearchParams(window.location.search)
  );

  const {user} = useAppContext();

  const [connectionList, setConnectionList] = useState();

  const {request, crmRequest} = useRequest();

  const updateConnectionConfig = chat_id =>
      crmRequest({
        crm: user.crm,
        ...updateConnectionConfigReq({
          domain: user.domain,
          chat_id,
          data: {line_id: line_id}
        })
      });

  const getConnectionList = async () => {
    const res = await request(
        getConnectionListReq({
          crm: user.crm,
          domain: user.domain
        })
    );

    const instance = res[chat_id];

    if (instance) {
      const {condition, is_paid, is_limited} = getConnectionCondition(instance);

      instance.condition = condition;
      instance.is_paid = is_paid;
      instance.is_limited = is_limited;

      setConnectionList([instance]);

      if (line_id) {
        updateConnectionConfig(instance.id);
      }

      return;
    }

    setConnectionList([]);
  };

  useEffect(() => {
    getConnectionList();
  }, []);

  return (
      <div className="home">
        <Page isTransparent>
          <ConnectionList
              connectionList={connectionList}
              client={_.pick(user, ['crm', 'domain', 'is_integration'])}
              isConnector
              setConnectionList={setConnectionList}
              onCreate={instance => updateConnectionConfig(instance.id)}
          />
        </Page>
      </div>
  );
};

export default Connector;
