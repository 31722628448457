import React from 'react';
import {t} from 'ttag';
import Line from '../../../line/line.jsx';
import Link from '../../../link/link.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';

const SettingsTrigger = () => {
  const {client, isConnector} = useConnectionListContext();
  const {connection, justCrm, setIsSettingsPopupOpen} = useConnectionContext();

  const isSettingsTriggerVisible =
    !isConnector &&
    !client.isCustomer &&
    (justCrm === 'bitrix24' || justCrm === 'alfacrm' ||
      ((justCrm === 'amocrm' || justCrm === 'monday') && connection.phone));

  return (
    <div style={isSettingsTriggerVisible ? undefined : {visibility: 'hidden'}}>
      <Line />

      <Link
        isFake
        isBold
        onClick={() => setIsSettingsPopupOpen(prevValue => !prevValue)}
      >
        {t`Change settings`}
      </Link>
    </div>
  );
};

export default SettingsTrigger;
