import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';

import Wrapper from '../wrapper/wrapper.jsx';
import Grid from '../grid/grid.jsx';
import Preloader from '../preloader/preloader.jsx';
import EmptyBox from '../empty-box/empty-box.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest, useWebSocket} from '../../common/hooks.js';
import {getTariffListReq} from '../../common/api/api.js';
import {
    getPipelineListReq as getAmocrmPipelineListReq,
    getManagerListReq
} from '../../common/api/amo.js';
import {getPipelineListReq as getAlfacrmPipelineListReq} from '../../common/api/alfacrm.js';
import {filterArray} from '../../common/actions.js';

import Advices from './advices/advices.jsx';
import Actions from './actions/actions.jsx';
import FormObserver from './form-observer/form-observer.jsx';
import Connection from './connection/connection.jsx';
import ConnectionListContext from './connection-list-context.js';

const ConnectionList = ({
                            connectionList,
                            client,
                            isConnector,
                            setConnectionList,
                            onCreate
                        }) => {
    const {user, lang} = useAppContext();

    const [visibleConnectionList, setVisibleConnectionList] = useState();
    const [tariffList, setTariffList] = useState();

    const [selectedConnectionIdList, setSelectedConnectionIdList] = useState([]);
    const [openConnectionId, setOpenConnectionId] = useState(-1);

    const [connectionRequiringPreset, setConnectionRequiringPreset] =
        useState(-1);

    const [isSelection, setIsSelection] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const [pipelineList, setPipelineList] = useState([]);
    const [managerList, setManagerList] = useState([]);

    const {request, crmRequest} = useRequest();
    const {connectWebSocket, listenWebSocket} = useWebSocket();

    const getTariffList = async () => {
        const res = await request(
            getTariffListReq({
                crm: client.crm,
                country: user.country,
                lang: lang,
                isPartner: client.isCustomer ? 1 : 0
            })
        );

        setTariffList(res);
    };

    const getPipelineList = async () => {
        let pipelines_params = {};

        let crmName = client.crm;
        if (crmName === 'LK' && client.widget_code === 'ALFACRM') {
            crmName = 'ALFACRM';
        }

        if (crmName === 'AMO' || crmName === 'TELEGRAM') {
            pipelines_params = getAmocrmPipelineListReq(client.domain);
        } else if (crmName === 'ALFACRM') {
            pipelines_params = getAlfacrmPipelineListReq(client.domain);
        }

        let pipelines = await crmRequest({
            crm: crmName,
            ...pipelines_params
        });

        setPipelineList(Array.isArray(pipelines) ? pipelines : []);
    };

    const getManagerList = async () => {
        const res = await crmRequest({
            crm: client.crm,
            ...getManagerListReq(client.domain)
        });

        setManagerList(res);
    };

    useEffect(() => {
        if (!client.isCustomer) {
            connectWebSocket();
        }

        if (
            client.crm === 'AMO' ||
            client.crm === 'TELEGRAM' ||
            (client.crm === 'LK' && client.widget_code === 'ALFACRM')
        ) {
            getPipelineList();

            if (client.crm === 'AMO' || client.crm === 'TELEGRAM') {
                getManagerList();
            }
        }

        setIsMounted(true);
    }, []);

    useEffect(() => {
        getTariffList();
    }, [user.country]);

    const handleChange = values => {
        let filteredConnectionList = connectionList
            ? [...connectionList]
            : undefined;

        if (values.search) {
            filteredConnectionList = filterArray(values.search, connectionList, [
                'id',
                'label',
                'phone',
                'name'
            ]);
        }

        if (values.category !== 'all') {
            filteredConnectionList = filteredConnectionList.filter(item => {
                switch (values.category) {
                    case 'inactive':
                        return !item.phone;
                    case 'unconfigured':
                        return item.is_configured === false;
                    case 'unpaid':
                        return !item.is_paid;
                    default:
                        return true;
                }
            });
        }

        setVisibleConnectionList(filteredConnectionList);
    };

    const isLoaded = visibleConnectionList && isMounted;

    return (
        <ConnectionListContext.Provider
            value={{
                connectionList,
                visibleConnectionList,
                client,
                tariffList,
                selectedConnectionIdList,
                openConnectionId,
                connectionRequiringPreset,
                isConnector,
                isSelection,
                pipelineList,
                managerList,
                setConnectionList,
                onCreate,
                setVisibleConnectionList,
                setSelectedConnectionIdList,
                setOpenConnectionId,
                setConnectionRequiringPreset,
                setIsSelection,
                listenWebSocket
            }}
        >
            <div>
                <Wrapper gap={16} isColumn>
                    <Wrapper gap={16} isColumn>
                        <Advices/>

                        <Formik initialValues={{search: '', category: 'all'}}>
                            <>
                                <Actions/>
                                <FormObserver onChange={handleChange}/>
                            </>
                        </Formik>
                    </Wrapper>

                    {isLoaded ? (
                        visibleConnectionList.length ? (
                            <Grid elements={2}>
                                {connectionList
                                    .sort((a, b) => a.id - b.id)
                                    .map(item => (
                                        <Connection
                                            key={item.id}
                                            connection={item}
                                            isVisible={
                                                !!visibleConnectionList.find(
                                                    visible => visible.id === item.id
                                                )
                                            }
                                        />
                                    ))}
                            </Grid>
                        ) : (
                            <EmptyBox/>
                        )
                    ) : (
                        <Preloader/>
                    )}
                </Wrapper>
            </div>
        </ConnectionListContext.Provider>
    );
};

ConnectionList.propTypes = {
    connectionList: PropTypes.array,
    client: PropTypes.shape({
        crm: PropTypes.string,
        domain: PropTypes.string,
        isCustomer: PropTypes.bool
    }),
    isConnector: PropTypes.bool,
    setConnectionList: PropTypes.func,
    onCreate: PropTypes.func
};

ConnectionList.defaultProps = {
    connectionList: null,
    client: {},
    isConnector: false,
    setConnectionList: () => {
    },
    onCreate: () => {
    }
};

export default ConnectionList;
