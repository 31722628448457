import React from 'react';
import Variable from './variable/variable.jsx';

const VariableList = ({onSelect}) => {
  const variableList = [
    'CLIENT_NAME',
    'CLIENT_NUMBER',
    'COMPANY_NAME',
    'COMPANY_NUMBER',
    'TODAY',
    'TOMORROW'
  ];

  return (
    <div className="variable-list">
      {variableList.map((item, i) => (
        <Variable
          key={i}
          variable={item}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

export default VariableList;
