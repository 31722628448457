import React from 'react';
import PropTypes from 'prop-types';
import Bitrix24 from './bitrix24/bitrix24.jsx';
import Amocrm from './amocrm/amocrm.jsx';
import Alfacrm from "./alfacrm/alfacrm";
import {default as CorePopup} from '../../../../popup/popup.jsx';
import Name from '../../name/name.jsx';
import {useConnectionContext} from '../../connection-context.js';
import {useSettingsContext} from '../settings-context.js';

const Popup = ({isClosable}) => {
  const {justCrm} = useConnectionContext();
  const {setIsSettingsPopupOpen} = useSettingsContext();

  return (
    <CorePopup
      width={644}
      isClosable={isClosable}
      onClick={() => setIsSettingsPopupOpen(false)}
    >
      <Name style={{marginBottom: 16}} />

      {justCrm === 'amocrm' && <Amocrm />}
      {justCrm === 'bitrix24' && <Bitrix24 />}
      {justCrm === 'alfacrm' && <Alfacrm />}
    </CorePopup>
  );
};

Popup.propTypes = {
  isClosable: PropTypes.bool
};

Popup.defaultProps = {
  isClosable: true
};

export default Popup;
