import React, {useEffect} from 'react';
import update from 'immutability-helper';
import Output from './output/output.jsx';
import LinkingPopup from './linking-popup/linking-popup.jsx';
import {useConnectionListContext} from '../../../connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';

const PhoneNumber = () => {
  const {
    connectionList,
    client,
    openConnectionId,
    isConnector,
    setConnectionList,
    setOpenConnectionId,
    listenWebSocket
  } = useConnectionListContext();

  const {connection, setConnectionStatus, setIsSettingsPopupOpen} =
    useConnectionContext();

  const handleConnect = values => {
    const index = connectionList.findIndex(item => item.id === connection.id);

    setConnectionList(prevValue =>
      update(prevValue, {
        [index]: {
          phone: {
            $set:
              connection.version === 'telegram'
                ? values.phoneNumber
                : values.me.user
          },
          name: {$set: values.pushname}
        }
      })
    );

    setOpenConnectionId(-1);

    if (client.crm !== 'LK' && !isConnector) {
      setIsSettingsPopupOpen(true);
    }
  };

  useEffect(() => {
    listenWebSocket({
      chat_key: connection.chat_key,
      onChange: setConnectionStatus,
      onConnect: handleConnect
    });
  }, []);

  return (
    <>
      <Output />
      {openConnectionId === connection.id && <LinkingPopup />}
    </>
  );
};

export default PhoneNumber;
