import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {t} from 'ttag';
import {
  TableRow,
  TableCell
} from '../../../../../../components/table/table.jsx';
import Confirmation from '../../../../../../components/confirmation/confirmation.jsx';
import Button from '../../../../../../components/button/button.jsx';
import Icons from '../../../../../../components/icons/icons.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useCustomerListContext} from '../../customer-list-context.js';
import {useCustomerContext} from '../customer-context.js';
import {useRequest} from '../../../../../../common/hooks.js';
import {deleteCustomerReq} from '../../../../../../common/api/api.js';
import {customerPropTypes} from '../../prop-types.js';

const BasicInfo = ({isOpen, setIsOpen}) => {
  const {user} = useAppContext();

  const {customerList, crmList, setCustomerList} = useCustomerListContext();

  const {customer} = useCustomerContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {request} = useRequest();

  const type = crmList[customer.type_crm].name;

  const deleteCustomer = async () => {
    const res = await request(
      deleteCustomerReq({
        crm: user.crm,
        domain: user.domain,
        data: {
          crm: customer.type_crm,
          domain: customer.domain
        }
      })
    );

    if (res.success) {
      const index = customerList.findIndex(item => item.id === customer.id);

      setCustomerList(
        update(customerList, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  return (
    <TableRow>
      <TableCell>{customer.domain}</TableCell>

      <TableCell>{type}</TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          onClick={() => setIsConfirmationOpen(true)}
        >
          <Icons.Trash />
        </Button>

        {isConfirmationOpen && (
          <Confirmation
            title={t`Are you sure you want to remove your customer ${customer.domain}?`}
            onClick={res =>
              res ? deleteCustomer() : setIsConfirmationOpen(false)
            }
          />
        )}
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icons.ArrowDown isRotate={isOpen} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

BasicInfo.propTypes = {
  customer: customerPropTypes,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired
};

export default BasicInfo;
