import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Input} from '../../../../components/form/form.jsx';

const Email = () => {
  const [field, meta, helpers] = useField('email');

  const {error, touched} = meta;
  const {setValue} = helpers;

  const isValid = !(error && touched);

  return (
    <Input
      {...field}
      type="email"
      placeholder={t`Email`}
      isValid={isValid}
      onChange={value => setValue(value.toLowerCase())}
    />
  );
};

export default Email;
