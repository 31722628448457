import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import cn from 'classnames';
import * as Title from '../../title/title.jsx';
import Text from '../../text/text.jsx';
import Closer from '../../closer/closer.jsx';
import {useAppContext} from '../../../app-context.js';

const Item = ({index, data}) => {
  const {notificationList, setNotificationList} = useAppContext();

  const [isClosing, setIsClosing] = useState();

  const remove = () => {
    setNotificationList(
      update(notificationList, {
        $splice: [[index, 1]]
      })
    );
  };

  const hide = () => {
    setIsClosing(true);
    setTimeout(() => remove(), 200);
  };

  useEffect(() => {
    const timeout = setTimeout(() => hide(), 4000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={cn('notification-list__item', {
        'notification-list__item_closing': isClosing
      })}
    >
      <Title.H2
        color="white"
        style={data.text ? {marginBottom: 8} : {}}
      >
        {data.title}
      </Title.H2>

      <Text color="white">{data.text}</Text>

      <Closer
        color="transparent"
        onClick={hide}
      />
    </div>
  );
};

Item.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  }).isRequired
};

Item.defaultProps = {
  index: 0,
  data: {}
};

export default Item;
