import React, {useState} from 'react';
import update from 'immutability-helper';
import * as Title from '../../../../../../components/title/title.jsx';
import Text from '../../../../../../components/text/text.jsx';
import Link from '../../../../../../components/link/link.jsx';
import Button from '../../../../../../components/button/button.jsx';
import Confirmation from '../../../../../../components/confirmation/confirmation.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useRequest} from '../../../../../../common/hooks.js';
import {updateUserReq} from '../../../../../../common/api/api.js';

const Info = () => {
  const {user, setUser} = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const resetTelphinCode = async () => {
    const res = await request(
      updateUserReq({
        crm: user.crm,
        domain: user.domain,
        data: {telphin_code: ''}
      })
    );

    if ('telphin_code' in res) {
      setUser(
        update(user, {
          telphin_code: {$set: res.telphin_code}
        })
      );
    }

    return true;
  };

  return (
    <>
      {user.telphin_email ? (
        <>
          <Title.H3 style={{marginBottom: 8}}>
            Данные компании успешно сохранены
          </Title.H3>

          <Text style={{marginBottom: 16}}>
              Вам доступно формирование счета
          </Text>
        </>
      ) : (
        <>
          <Title.H3 style={{marginBottom: 8}}>Вы уже зарегистрированы</Title.H3>

          <Text style={{marginBottom: 16}}>
            {`Ваш ИНН компании `}
            <Text
              color="dark"
              isSpan
              isBold
            >
              {user.telphin_code}
            </Text>
            .
          </Text>
        </>
      )}

      <Button
        color="white"
        onClick={() => setIsOpen(true)}
      >
        Сбросить
      </Button>

      {isOpen && (
        <Confirmation
          title="Вы уверены, что хотите сбросить компанию?"
          onClick={res => (res ? resetTelphinCode() : setIsOpen(false))}
        />
      )}
    </>
  );
};

export default Info;
