import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class Title extends React.Component {
  constructor({children, className, color, background, style, onClick}) {
    super();

    this.state = {
      children,
      className: cn('title', {
        [`title_${color}`]: color,
        [`title_bg title_bg-${background}`]: background,
        [className]: className
      }),
      style
    };

    this.color = color;
    this.style = style;
    this.onClick = onClick;
  }

  componentDidMount() {
    const {children, className, color, background} = this.props;

    this.setState({
      children,
      className: cn('title', {
        [`title_${color}`]: color,
        [`title_bg title_bg-${background}`]: background,
        [className]: className
      })
    });
  }

  componentDidUpdate(prevProps) {
    const {children, className, color, background} = this.props;

    if (
      prevProps.children !== children ||
      prevProps.color !== color ||
      prevProps.background !== background ||
      prevProps.className !== className
    ) {
      this.setState({
        children,
        className: cn('title', {
          [`title_${color}`]: color,
          [`title_bg title_bg-${background}`]: background,
          [className]: className
        })
      });
    }
  }
}

export class H1 extends Title {
  render() {
    return (
      <h1
        className={[this.state.className, 'title_h1'].join(' ')}
        style={this.style}
        onClick={this.onClick}
      >
        {this.state.children}
      </h1>
    );
  }
}

export class H2 extends Title {
  render() {
    return (
      <h2
        className={[this.state.className, 'title_h2'].join(' ')}
        style={this.style}
        onClick={this.onClick}
      >
        {this.state.children}
      </h2>
    );
  }
}

export class H3 extends Title {
  render() {
    return (
      <h3
        className={[this.state.className, 'title_h3'].join(' ')}
        style={this.style}
        onClick={this.onClick}
      >
        {this.state.children}
      </h3>
    );
  }
}

Title.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  background: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

Title.defaultProps = {
  children: <></>,
  color: '',
  background: '',
  style: {},
  onClick: () => {}
};
