import React from 'react';

import Wrapper from '../../../../wrapper/wrapper.jsx';
import Text from '../../../../text/text.jsx';
import Button from '../../../../button/button.jsx';
import Icons from '../../../../icons/icons.jsx';
import {useConnectionContext} from '../../connection-context.js';
import {useCopyToClipboard} from '../../../../../common/hooks.js';

const Id = () => {
  const {connection} = useConnectionContext();
  const copyToClipboard = useCopyToClipboard();

  return (
    <>
      <Wrapper
        alignItems="center"
        gap={0}
      >
        <Text>{`ID: ${connection.id}`}</Text>

        <Button
          color="transparent"
          style={{height: 16}}
          onClick={() => copyToClipboard(connection.id)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>
    </>
  );
};

export default Id;
