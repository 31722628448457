import React, {useEffect} from 'react';
import _ from 'lodash';

import ProgressBar from '../../../../../../progress-bar/progress-bar.jsx';
import TelegramAuthentication from '../../../../../../telegram-authentication/telegram-authentication.jsx';
import Name from '../../../../name/name.jsx';
import {useConnectionContext} from '../../../../connection-context.js';
import {useRequest} from '../../../../../../../common/hooks.js';
import {getConnectionStatusReq} from '../../../../../../../common/api/api.js';

import Scanning from './scanning/scanning.jsx';

const Linking = () => {
  const {connection, connectionStatus, setConnectionStatus} =
    useConnectionContext();

  const {request} = useRequest();

  const startContainer = async () => {
    const res = await request(
      getConnectionStatusReq({
        chat_key: connection.chat_key,
        full: 1
      })
    );

    setConnectionStatus(_.pick(res, ['state', 'qrCode']));
  };

  useEffect(() => {
    if (!connectionStatus.qrCode) {
      startContainer();
    }
  }, []);

  return (
    <>
      {connectionStatus.state === 'LOADING_SCREEN' ? (
        <ProgressBar percent={connectionStatus.percent} />
      ) : connectionStatus.state === 'authorizationStateWaitPassword' ? (
        <TelegramAuthentication
          chat_key={connection.chat_key}
          connectionName={
            <Name
              style={{marginBottom: 16}}
              passwordHint={connectionStatus.passwordHint}
            />
          }
        />
      ) : (
        <Scanning startContainer={startContainer} />
      )}
    </>
  );
};

export default Linking;
