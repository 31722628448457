import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Popup from '../popup/popup.jsx';
import * as Title from '../title/title.jsx';
import Text from '../text/text.jsx';
import Line from '../line/line.jsx';
import Wrapper from '../wrapper/wrapper.jsx';
import Button, {SaveButton} from '../button/button.jsx';
import {useOnKeyDown} from '../../common/hooks.js';

const Confirmation = ({title, text, onClick}) => {
  useOnKeyDown({onEnter: () => onClick(true), onEscape: () => onClick(false)});

  return (
    <Popup onClick={() => onClick(false)}>
      <Title.H2 style={{marginBottom: 8}}>{title}</Title.H2>
      <Text>{text}</Text>
      <Line />

      <Formik
        initialValues={{}}
        onSubmit={() => onClick(true)}
      >
        <Form>
          <Wrapper alignItems="center">
            <SaveButton>{t`Yes`}</SaveButton>

            <Button
              color="white"
              onClick={() => onClick(false)}
            >
              {t`No`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Confirmation.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  onClick: PropTypes.func
};

Confirmation.defaultProps = {
  title: '',
  text: '',
  onClick: () => {}
};

export default Confirmation;
