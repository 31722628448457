import React from 'react';
import PropTypes from 'prop-types';
import Notice from './notice/notice.jsx';

const NoticeList = ({noticeList, setNoticeList}) => (
  <>
    <div className="notice-list">
      {noticeList.map((item, i) => (
        <Notice
          key={item.id}
          index={i}
          notice={item}
          setNoticeList={setNoticeList}
        />
      ))}
    </div>
  </>
);

NoticeList.propTypes = {
  noticeList: PropTypes.arrayOf(PropTypes.object),
  setNoticeList: PropTypes.func
};

NoticeList.defaultProps = {
  noticeList: [],
  setNoticeList: () => {}
};

export default NoticeList;
