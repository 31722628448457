import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Toggle} from '../../../../../../form/form.jsx';

const FirstMessage = () => {
  const [field, , helpers] = useField('first_message');

  return (
    <Toggle
      label={t`Creating an open line when sending a message via SMS`}
      isChecked={field.value}
      onChange={helpers.setValue}
    />
  );
};

export default FirstMessage;
