import React from 'react';
import {t} from 'ttag';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import {Radio} from '../../../../form/form.jsx';
import Line from '../../../../line/line.jsx';
import {SaveButton} from '../../../../button/button.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {putInLocalStorage} from '../../../../../common/actions.js';

const Theme = () => {
  const {theme, setTheme} = useAppContext();

  const handleSubmit = values => {
    setTheme(values.theme);
    putInLocalStorage('theme', values.theme);
    return true;
  };

  const options = [
    {
      value: 'light',
      label: t`Light`
    },
    {
      value: 'dark',
      label: t`Dark`
    },
    {
      value: 'system',
      label: t`Sync with system settings`
    }
  ];

  const initialValues = {theme};

  const validationSchema = yup.object().shape({
    theme: yup.string().trim().required()
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({values, setFieldValue}) => (
        <Form>
          <Radio
            options={options.map(item => ({
              ...item,
              isChecked: item.value === values.theme
            }))}
            name="theme"
            onChange={value => setFieldValue('theme', value)}
          />

          <Line />

          <SaveButton>{t`Save`}</SaveButton>
        </Form>
      )}
    </Formik>
  );
};

export default Theme;
