import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const TableCell = ({children, isIcon, isFullWidth}) => {
  const ref = useRef();

  return (
    <td
      ref={ref}
      className={cn('table__cell', {
        table__cell_icon: isIcon
      })}
      colSpan={isFullWidth ? 100 : null}
    >
      {children}
    </td>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  isFullWidth: PropTypes.bool
};
