import React from 'react';
import {t} from 'ttag';
import Page from '../../../components/page/page.jsx';
import Text from '../../../components/text/text.jsx';
import Link from '../../../components/link/link.jsx';
import EmptyBox from '../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../app-context.js';

const Error403 = () => {
  const {whatsAppSupport} = useAppContext();

  return (
    <Page
      isCenter
      isTransparent
    >
      <EmptyBox title="403 Forbidden">
        <Text color="dark">
          {t`Contact us on`}
          {' '}
          <Link
            href={whatsAppSupport}
            target="_blank"
          >
            WhatsApp
          </Link>{' '}
          {t`or`}
          {' '}
          <Link
            href="https://t.me/whatcrmbot"
            target="_blank"
          >
            Telegram
          </Link>
          .
        </Text>
      </EmptyBox>
    </Page>
  );
};

export default Error403;
