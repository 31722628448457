import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import {t} from 'ttag';

import Payment from '../payment/payment.jsx';
import Preloader from '../../preloader/preloader.jsx';
import EmptyBox from '../../empty-box/empty-box.jsx';
import ConnectionContext from './connection-context.js';
import {useAppContext} from '../../../app-context.js';
import {useConnectionListContext} from '../connection-list-context.js';
import {useRequest} from '../../../common/hooks.js';
import {getConnectionStatusReq} from '../../../common/api/api.js';

import Deleting from './deleting/deleting.jsx';
import Aside from './aside/aside.jsx';
import Name from './name/name.jsx';
import Wrapper from '../../wrapper/wrapper.jsx';
import Condition from './condition/condition.jsx';
import Info from './info/info.jsx';
import Settings from './settings/settings.jsx';
import SettingsTrigger from './settings-trigger/settings-trigger.jsx';

const Connection = ({connection, isVisible}) => {
  const {user} = useAppContext();
  const {client, isConnector} = useConnectionListContext();

  const [connectionStatus, setConnectionStatus] = useState();
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const {request} = useRequest();

  const getConnectionStatus = async () => {
    const res = await request(
        getConnectionStatusReq({chat_key: connection.chat_key})
    );

    setConnectionStatus(_.pick(res, ['state', 'qrCode']));
  };

  useEffect(() => {
    if (!client.isCustomer) {
      getConnectionStatus();
    }
  }, []);

  let date =
      connection.condition === 'trial'
          ? connection.date_trial
          : connection.condition === 'paid'
              ? connection.date_subscription
              : null;

  date = moment.unix(date).format('L');

  const paymentCondition =
      connection.condition === 'premium'
          ? {text: t`Premium`, background: 'blue'}
          : connection.condition === 'trial'
              ? {text: t`Trial until ${date}`, background: 'blue'}
              : connection.condition === 'paid'
                  ? {text: t`Paid until ${date}`, background: 'green'}
                  : {text: t`Requires payment`, background: 'red'};

  const justCrm =
      client.crm === 'AMO' || client.crm === 'TELEGRAM'
          ? 'amocrm'
          : client.crm === 'B24' || client.crm === 'TELPHGRAM'
              ? 'bitrix24'
              : client.crm === 'LK' && client.widget_code === 'ALFACRM'
                  ? 'alfacrm'
                  : undefined;

  const isConnected = client.isCustomer
      ? !!connection.phone
      : connection.phone &&
      (connectionStatus?.state === 'READY' ||
          connectionStatus?.state === 'CONNECTED' ||
          connectionStatus?.state === 'authorizationStateReady');

    let isSettingsShown = client.crm !== 'LK';
    if (client.widget_code === 'ALFACRM') {
        isSettingsShown = true;
    }
  const isDeletingShown = !!user.is_admin && !isConnector;

  return (
      <ConnectionContext.Provider
          value={{
            connection,
            connectionStatus,
            paymentCondition,
            isConnected,
            isSettingsPopupOpen,
            justCrm,
            setConnectionStatus,
            setIsSettingsPopupOpen,
            setIsPaymentOpen
          }}
      >
        <div
            className={cn('connection', {
              connection_visible: isVisible
            })}
        >
          {connection ? (
              Object.keys(connection).length > 0 ? (
                  <>
                    <div className="connection__inner">
                      <Aside />

                      <div className="connection__content">
                        <Wrapper
                            gap={16}
                            isColumn
                        >
                          <Wrapper
                              justifyContent="space-between"
                              gap={0}
                              noWrap
                          >
                            <Name isEditable />
                            {isDeletingShown && <Deleting />}
                          </Wrapper>

                          <Condition />
                          <Info />
                        </Wrapper>

                        <div>
                          {isSettingsShown && (
                              <>
                                <Settings />
                                <SettingsTrigger />
                              </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
              ) : (
                  <EmptyBox />
              )
          ) : (
              <Preloader />
          )}
        </div>

        {isPaymentOpen && (
            <Payment
                connection={connection}
                isTariffChanging={connection.is_paid}
                onClose={() => setIsPaymentOpen(false)}
            />
        )}
      </ConnectionContext.Provider>
  );
};

Connection.propTypes = {
  connection: PropTypes.shape({
    apikey: PropTypes.string,
    chat_id: PropTypes.string,
    chat_key: PropTypes.string,
    chat_token: PropTypes.string,
    condition: PropTypes.string,
    date_add: PropTypes.number,
    date_pay: PropTypes.number,
    date_subscription: PropTypes.number,
    date_trial: PropTypes.number,
    id: PropTypes.number,
    instanceId: PropTypes.string,
    is_configured: PropTypes.bool,
    is_limited: PropTypes.bool,
    is_paid: PropTypes.bool,
    is_premium: PropTypes.number,
    statuses_id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    pipeline_id: PropTypes.number,
    platform: PropTypes.string,
    status: PropTypes.string,
    tariff_id: PropTypes.number,
    tariff_plane: PropTypes.string,
    version: PropTypes.string
  }),
  isVisible: PropTypes.bool
};

Connection.defaultProps = {
  connection: {},
  isVisible: false
};

export default Connection;
