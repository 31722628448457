import React from 'react';
import {useFormikContext} from 'formik';
import Button from '../button.jsx';

const SaveButton = props => {
  const {isDisabled} = props;
  const {isSubmitting} = useFormikContext();

  const htmlIsDisabled = isDisabled || isSubmitting;

  return (
    <Button
      {...props}
      type="submit"
      isDisabled={htmlIsDisabled}
    />
  );
};

export default SaveButton;
