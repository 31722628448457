import React, {useEffect} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';

import Entry from '../entry/entry.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';

const Alfacrm = () => {
  const {connectionList, pipelineList, setConnectionList} =
    useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  const pipe_id = connectionConfig.pipeline_id || undefined;

  const pipeline = pipelineList.find(item => item.id === pipe_id);

  const connectionIndex = connectionList.findIndex(
    item => item.id === connection.id
  );

  useEffect(() => {
    setConnectionList(prevValue =>
      update(prevValue, {
        [connectionIndex]: {
          is_configured: {$set: !!pipeline}
        }
      })
    );
  }, [pipeline]);

  return (
    <>
      <Entry
        label={t`Филиал`}
        value={pipeline?.name || t`A selection is required`}
        color={!pipeline ? 'yellow' : undefined}
      />
      <Entry
        label={t`Working with groups`}
        value={connectionConfig.is_group ? t`Yes` : t`No`}
      />
    </>
  );
};

export default Alfacrm;
