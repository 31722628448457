import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as Title from '../../../../../../title/title.jsx';
import LocaleString from '../../../../../../locale-string/locale-string.jsx';
import {useAppContext} from '../../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../../connection-list-context.js';
import {usePaymentContext} from '../../../../payment-context.js';
import {useGetTariffName} from '../../../../../../../common/hooks.js';

const Option = ({option}) => {
  const {user} = useAppContext();
  const {client} = useConnectionListContext();
  const {selectedTariffId, setSelectedTariffId} = usePaymentContext();

  const getTariffName = useGetTariffName();

  const isDiscount = user.is_review === 2 && !client.isCustomer;
  const amount = isDiscount ? option.summa_review : option.summa;

  return (
    <div
      className={cn('tariff-plan__options-item', {
        'tariff-plan__options-item_selected': option.id === selectedTariffId
      })}
      onClick={() => setSelectedTariffId(option.id)}
    >
      <Title.H3
        color="green"
        style={{marginBottom: 8}}
      >
        {getTariffName(option)}
      </Title.H3>

      <Title.H2>
        <LocaleString
          amount={amount}
          type="currency"
          currency={option.currency}
        />
      </Title.H2>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    currency: PropTypes.string,
    description: PropTypes.string,
    group: PropTypes.number,
    id: PropTypes.number,
    managers: PropTypes.number,
    name: PropTypes.string,
    packet_name: PropTypes.string,
    period: PropTypes.string,
    quantity: PropTypes.number,
    sign: PropTypes.string,
    summa: PropTypes.string,
    summa_review: PropTypes.string,
    title: PropTypes.string,
    title_review: PropTypes.string,
    web: PropTypes.number
  })
};

Option.defaultProps = {
  option: {}
};

export default Option;
