import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Domain from './domain/domain.jsx';
import CRM from './crm/crm.jsx';
import Popup from '../../../../../components/popup/popup.jsx';
import * as Title from '../../../../../components/title/title.jsx';
import Line from '../../../../../components/line/line.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../../../components/button/button.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useCustomerListContext} from '../customer-list-context.js';
import {useRequest} from '../../../../../common/hooks.js';
import {addCustomerReq} from '../../../../../common/api/api.js';
import {trimValues} from '../../../../../common/actions.js';

const Creating = ({onClose}) => {
  const {user} = useAppContext();

  const {customerList, setCustomerList} = useCustomerListContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await request(
      addCustomerReq({
        crm: user.crm,
        domain: user.domain,
        data: trimmedValues
      })
    );

    if (res.success) {
      setCustomerList(
        update(customerList, {
          $push: [res.client]
        })
      );

      onClose();
    }

    return true;
  };

  const initialValues = {domain: '', crm: user.crm};

  const validationSchema = yup.object().shape({
    domain: yup.string().trim().required()
  });

  return (
    <Popup onClick={onClose}>
      <Title.H2 style={{marginBottom: 16}}>{t`Add a customer`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Domain />
          {user.crm === 'LK' && <CRM />}
          <Line />

          <Wrapper alignItems="center">
            <SaveButton>{t`Return`}</SaveButton>

            <Button
              color="white"
              onClick={onClose}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Creating.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Creating;
