import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTextareaAutosize from 'react-textarea-autosize';

const Textarea = ({
  name,
  value,
  placeholder,
  style,
  innerRef,
  isMax,
  onBlur,
  onChange
}) => {
  const htmlStyle = {
    ...style,
    resize: 'none'
  };

  return (
    <ReactTextareaAutosize
      ref={innerRef}
      className={cn('field textarea', {
        textarea_max: isMax
      })}
      name={name}
      value={value}
      placeholder={placeholder}
      style={htmlStyle}
      onBlur={onBlur}
      onChange={e => onChange(e.target.value)}
    />
  );
};

Textarea.propsTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  innerRef: PropTypes.func,
  isMax: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

Textarea.propsTypes = {
  name: '',
  value: '',
  placeholder: '',
  isMax: false,
  style: {},
  innerRef: () => {},
  onBlur: () => {},
  onChange: () => {}
};

export default Textarea;
