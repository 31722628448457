import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {t} from 'ttag';

import Popup from '../../popup/popup.jsx';
import Advice from '../../advice/advice.jsx';
import Wrapper from '../../wrapper/wrapper.jsx';
import * as Title from '../../title/title.jsx';
import Text from '../../text/text.jsx';
import Link from '../../link/link.jsx';
import Preloader from '../../preloader/preloader.jsx';
import EmptyBox from '../../empty-box/empty-box.jsx';
import {useAppContext} from '../../../app-context.js';
import {useConnectionListContext} from '../connection-list-context.js';
import {useRequest} from '../../../common/hooks.js';
import {triggerReviewReq} from '../../../common/api/api.js';

import TariffPlan from './tariff-plan/tariff-plan.jsx';
import PaymentContext from './payment-context.js';

const Payment = ({connection, connectionIdList, isTariffChanging, onClose}) => {
  const {user} = useAppContext();
  const {tariffList} = useConnectionListContext();

  const [selectedTariffId, setSelectedTariffId] = useState(
      connection.tariff_id
  );

  const {request} = useRequest();

  useEffect(() => {
    if (tariffList && Object.keys(tariffList).length > 0) {
      const tariffIdList = Object.entries(tariffList)
          .map(entry => Object.keys(entry[1]))
          .flat();

      if (tariffIdList.indexOf(selectedTariffId) === -1) {
        const {id} = Object.values(tariffList.Start).find(
            item => item.quantity === 3
        );

        setSelectedTariffId(id);
      }
    }
  }, [tariffList]);

  const openReviewPage = async () => {
    await request(
        triggerReviewReq({
          crm: user.crm,
          domain: user.domain
        })
    );

    const anchor = user.crm === 'AMO' ? 'amo_wapp_im' : 'telphgram';
    window.open(`https://${user.domain}/amo-market#${anchor}`, '_blank');
  };

  const subscriptionDate =
      connection.date_subscription * 1000 > Date.now()
          ? moment.unix(connection.date_subscription).format('L')
          : null;

  const isReviewLinkShown =
      !user.is_partner &&
      user.is_review !== 2 &&
      (user.crm === 'AMO' ||
          (user.crm === 'TELEGRAM' && user.domain.slice(-4) !== '.com'));

  return (
      <PaymentContext.Provider
          value={{
            connection,
            connectionIdList,
            selectedTariffId,
            setSelectedTariffId,
            closePayment: onClose
          }}
      >
        <Popup
            isMaxWide
            onClick={() => onClose()}
        >
          <Wrapper
              gap={16}
              isColumn
              style={{marginBottom: 24}}
          >
            <Wrapper isColumn>
              <Title.H1>
                {isTariffChanging
                    ? t`Change tariff plan`
                    : t`Choose a tariff plan`}
              </Title.H1>

              {isReviewLinkShown && (
                  <Link
                      isFake
                      onClick={openReviewPage}
                  >
                    {t`Give feedback and get a discount`}
                  </Link>
              )}
            </Wrapper>

            {subscriptionDate && (
                <Advice>
                  <Title.H3 style={{marginBottom: 8}}>
                    {t`The connection is paid until ${subscriptionDate}`}
                  </Title.H3>

                  <Text color="dark">
                    {t`If you pay again, the subscription period will start now and will not be added to the previous one.`}
                  </Text>
                </Advice>
            )}
          </Wrapper>

          {tariffList ? (
              Object.keys(tariffList).length > 0 ? (
                  <Wrapper
                      alignItems="center"
                      gap={32}
                  >
                    <TariffPlan tariffOptions={Object.values(tariffList.Start)} />
                    <TariffPlan tariffOptions={Object.values(tariffList.Pro)} />
                  </Wrapper>
              ) : (
                  <EmptyBox />
              )
          ) : (
              <Preloader />
          )}
        </Popup>
      </PaymentContext.Provider>
  );
};

Payment.propTypes = {
  connection: PropTypes.object,
  connectionIdList: PropTypes.array,
  isTariffChanging: PropTypes.bool,
  onClose: PropTypes.func
};

Payment.defaultProps = {
  connection: {},
  connectionIdList: [],
  isTariffChanging: false,
  onClose: () => {}
};

export default Payment;
