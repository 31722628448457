import React from 'react';
import PropTypes from 'prop-types';
import Option from './option/option.jsx';

const OptionList = ({options, matchingOptionIds, onChange}) => (
  <div
    className="select__options"
    style={{gap: 12}}
  >
    {options.map(item => (
      <Option
        key={item.value}
        option={item}
        matchingOptionIds={matchingOptionIds}
        onChange={onChange}
      />
    ))}
  </div>
);

OptionList.propTypes = {
  options: PropTypes.array,
  matchingOptionIds: PropTypes.array,
  onChange: PropTypes.func
};

OptionList.defaultProps = {
  options: [],
  matchingOptionIds: [],
  onChange: () => {}
};

export default OptionList;
