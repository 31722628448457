import React from 'react';

import Wrapper from '../../../wrapper/wrapper.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';

import Tariff from './tariff/tariff.jsx';
import PaymentDate from './payment-date/payment-date.jsx';
import Username from './username/username.jsx';
import PhoneNumber from './phone-number/phone-number.jsx';
import Key from './key/key.jsx';
import Id from './id/id.jsx';

const Info = () => {
    const {user} = useAppContext();
    const {client} = useConnectionListContext();
    const {connection} = useConnectionContext();

    const isKeyShown = !!(
        !connection.is_limited &&
        !client.isCustomer &&
        user.widget_code &&
        user.crm == 'LK'
    );

    return (
        <Wrapper isColumn>
            <Tariff />
            <PaymentDate />
            <Username />
            <PhoneNumber />
            <Key />
            <Id />
        </Wrapper>
    );
};

export default Info;
