import React, {useState, useRef} from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import EmojiPicker from './emoji-picker/emoji-picker.jsx';
import VariableList from './variable-list/variable-list.jsx';
import Text from '../../../../../components/text/text.jsx';
import {Textarea} from '../../../../../components/form/form.jsx';
import Button from '../../../../../components/button/button.jsx';
import Icons from '../../../../../components/icons/icons.jsx';

const Message = () => {
  const [textarea, setTextarea] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef();

  const [field, , helpers] = useField('message');
  const {value} = field;
  const {setValue} = helpers;

  const handleClick = () => {
    textarea.focus();
    setIsOpen(!isOpen);
  };

  const insertSubstring = substring => {
    textarea.focus();

    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;

    setValue(
      [
        value.substring(0, selectionStart),
        substring,
        value.substring(selectionEnd)
      ].join('')
    );

    setTimeout(() => {
      textarea.selectionEnd = selectionEnd + substring.length;
    });
  };

  return (
    <div style={{marginBottom: 16}}>
      <Text style={{marginBottom: 8}}>{t`Message text`}</Text>

      <div
        className="position-relative"
        style={{display: 'flex', marginBottom: 8}}
      >
        <Textarea
          {...field}
          innerRef={setTextarea}
          placeholder={t`Message text`}
          isMax
          onChange={setValue}
        />

        <Button
          ref={triggerRef}
          color="transparent"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0
          }}
          onClick={handleClick}
        >
          <Icons.Emoji />
        </Button>
      </div>

      <VariableList onSelect={insertSubstring} />

      {isOpen && (
        <EmojiPicker
          triggerRef={triggerRef}
          onClose={() => setIsOpen(false)}
          onSelect={insertSubstring}
        />
      )}
    </div>
  );
};

export default Message;
