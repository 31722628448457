import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const TableRow = forwardRef(({children, isOpen, isCollapsible}, ref) => (
  <tr
    ref={ref}
    className={cn('table__row', {
      table__row_open: isOpen,
      table__row_collapsible: isCollapsible
    })}
  >
    {children}
  </tr>
));

TableRow.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isCollapsible: PropTypes.bool
};

TableRow.defaultProps = {
  children: <></>,
  isOpen: false,
  isCollapsible: false
};
