import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Wrapper from '../../wrapper/wrapper.jsx';
import Text from '../../text/text.jsx';

const Checkbox = ({label, isChecked, isDisabled, style, onChange}) => {
  const id = _.uniqueId('checkbox-');

  return (
    <label
      className={cn('checkbox__label', {
        checkbox__label_disabled: isDisabled
      })}
      htmlFor={id}
      style={style}
    >
      <Wrapper
        alignItems="center"
        noWrap
      >
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          disabled={isDisabled}
          onChange={e => onChange(e.target.checked)}
        />

        <div className="checkbox" />

        {label && (
          <Text
            color="dark"
            isSpan
          >
            {label}
          </Text>
        )}
      </Wrapper>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  label: '',
  isChecked: false,
  isDisabled: false,
  style: {},
  onChange: () => {}
};

export default Checkbox;
