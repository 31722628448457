import React from 'react';

import Wrapper from '../../wrapper/wrapper.jsx';
import {useConnectionListContext} from '../connection-list-context.js';

import Creating from './creating/creating.jsx';
import Categorization from './categorization/categorization.jsx';
import Searching from './searching/searching.jsx';

const Actions = () => {
  const {connectionList, isConnector} = useConnectionListContext();

  const isCreatingShown =
    !isConnector || (isConnector && connectionList?.length === 0);

  return (
    <Wrapper gap={16}>
      {isCreatingShown && <Creating />}
      {connectionList?.length > 1 && <Categorization />}
      {connectionList?.length > 4 && <Searching />}
    </Wrapper>
  );
};

export default Actions;
