import React from 'react';
import {t} from 'ttag';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import Text from '../../../../text/text.jsx';
import Button from '../../../../button/button.jsx';
import Icons from '../../../../icons/icons.jsx';
import {useConnectionListContext} from '../../../connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';
import {useCopyToClipboard} from '../../../../../common/hooks.js';

const Key = () => {
    const {client} = useConnectionListContext();
    const {connection} = useConnectionContext();

    const copyToClipboard = useCopyToClipboard();

    //const isShown = !!(client.is_integration && !connection.is_limited);

    return (
        <>
            <Wrapper
                alignItems="center"
                gap={0}
            >
                <Text>{`${t`Key`}: ${connection.chat_key}`}</Text>

                <Button
                    color="transparent"
                    style={{height: 16}}
                    onClick={() => copyToClipboard(connection.chat_key)}
                >
                    <Icons.Copy/>
                </Button>
            </Wrapper>

        </>
    );
};

export default Key;
