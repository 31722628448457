import React from 'react';
import Option from './option/option.jsx';
import {useTariffPlanContext} from '../../tariff-plan-context.js';

const OptionList = () => {
  const {tariffOptions} = useTariffPlanContext();

  return (
    <div
      className="tariff-plan__options"
      style={{marginBottom: 16}}
    >
      {tariffOptions.map(item => (
        <Option
          key={item.id}
          option={item}
        />
      ))}
    </div>
  );
};

export default OptionList;
