export const getConnectionConfigReq = ({domain, chat_key}) => ({
    path: `settings/info`,
    params: {
        domain: domain,
        chat_key: chat_key
    }
});

export const updateConnectionConfigReq = ({domain, chat_key, data}) => ({
    path: `settings/update`,
    params: {
        domain: domain,
        chat_key: chat_key
    },
    method: 'post',
    data
});

export const getPipelineListReq = domain => ({
    path: `settings`,
    params: {domain}
});