import React from 'react';
import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Icons from '../../../../../icons/icons.jsx';
import Text from '../../../../../text/text.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useTariffPlanContext} from '../../tariff-plan-context.js';
import {getFeatureList} from './actions.js';

const FeatureList = () => {
  const {client} = useConnectionListContext();
  const {tariffOptions} = useTariffPlanContext();

  const {packet_name} = tariffOptions[0];
  const featureList = getFeatureList(client.crm);

  return (
    <div style={{marginBottom: 16}}>
      {featureList.map((item, i) => (
        <Wrapper
          key={i}
          alignItems="center"
          gap={0}
          noWrap
        >
          {item[packet_name] ? <Icons.Check /> : <Icons.Times color="light" />}

          <Text
            color={item[packet_name] ? 'dark' : 'light'}
            isSpan
          >
            {item.name}
          </Text>
        </Wrapper>
      ))}
    </div>
  );
};

export default FeatureList;
