import {t} from 'ttag';

export const getFeatureList = crm =>
  [
    {
      name: t`Write first`,
      skip: crm === 'TELEGRAM' || crm === 'TELPHGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Write first from any connection by phone number or username`,
      skip: crm !== 'TELEGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Write first from any connection by phone number`,
      skip: crm !== 'TELPHGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Working with Salesbot`,
      skip: crm !== 'AMO' && crm !== 'TELEGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Working with chat bots and business Processes`,
      skip: crm !== 'B24' && crm !== 'TELPHGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Unlimited dialogues`,
      Start: true,
      Pro: true
    },
    {
      name: t`Sending and receiving files`,
      skip: crm === 'TELEGRAM' || crm === 'TELPHGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Sending and receiving files (photos up to 5 MB, other files up to 20 MB)`,
      skip: crm !== 'TELEGRAM' && crm !== 'TELPHGRAM',
      Start: true,
      Pro: true
    },
    {
      name: t`Choosing a pipeline for a phone number`,
      skip: crm !== 'TELEGRAM',
      Start: true,
      Pro: true
    },
    /*{
      name: 'Chitoff Web',
      Start: crm === 'LK',
      Pro: true
    },*/
    {
      name: t`Working with group chats`,
      Start: false,
      Pro: true
    },
    {
      name: t`Missed calls`,
      skip: crm === 'B24' || crm === 'TELPHGRAM',
      Start: false,
      Pro: true
    },
    {
      name: t`Sending and displaying reactions`,
      Start: false,
      Pro: true
    },
    {
      name: 'Автоматизация',
      Start: false,
      Pro: true
    },
    {
      name: 'Отправка SMS (если нет WhatsApp)',
      Start: false,
      Pro: true
    },
    {
      name: t`Distribution of incoming leads by strategies`,
      skip: crm !== 'AMO' && crm !== 'TELEGRAM',
      Start: false,
      Pro: true
    }
  ].filter(item => !item.skip);
