import React from 'react';
import update from 'immutability-helper';
import cn from 'classnames';
import {Checkbox} from '../../../form/form.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';

const Aside = () => {
  const {selectedConnectionIdList, isSelection, setSelectedConnectionIdList} =
    useConnectionListContext();

  const {connection} = useConnectionContext();

  const handleChange = value => {
    if (value) {
      setSelectedConnectionIdList(prevValue =>
        update(prevValue, {
          $push: [connection.id]
        })
      );

      return;
    }

    const index = selectedConnectionIdList.indexOf(connection.id);

    setSelectedConnectionIdList(prevValue =>
      update(prevValue, {
        $splice: [[index, 1]]
      })
    );
  };

  const isAsideShown =
    isSelection && (connection.condition === 'unpaid' || !connection.is_paid);

  const isChecked = selectedConnectionIdList.includes(connection.id);

  return (
    <div className="connection__aside">
      <div
        className={cn('connection__aside-slider', {
          'connection__aside-slider_shown': isAsideShown
        })}
      >
        <Checkbox
          isChecked={isChecked}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Aside;
