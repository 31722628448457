import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import Section from '../../../components/section/section.jsx';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '../../../components/table/table.jsx';
import Article from '../../../components/article/article.jsx';
import Text from '../../../components/text/text.jsx';
import Button from '../../../components/button/button.jsx';
import Icons from '../../../components/icons/icons.jsx';
import Preloader from '../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../app-context.js';
import {useRequest} from '../../../common/hooks.js';
import {getDenyListReq} from '../../../common/api/api.js';

import Denied from './denied/denied.jsx';
import Editor from './editor/editor.jsx';

const DenyList = () => {
  const {user} = useAppContext();

  const [denyList, setDenyList] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const getDenyList = async () => {
    const res = await request(
      getDenyListReq({crm: user.crm, domain: user.domain})
    );

    setDenyList(res);
  };

  useEffect(() => {
    getDenyList();
  }, []);

  return (
    <Section title={t`Deny list`}>
      {denyList ? (
        <>
          <Article>
            <Text
              color="dark"
              isBold
              style={{marginBottom: 12}}
            >
              {t`Messages from blocked contacts will not come to CRM.`}
            </Text>
          </Article>

          <Button
            style={{marginBottom: 16}}
            onClick={() => setIsOpen(true)}
          >
            <Icons.Plus />
            {t`Add a contact`}
          </Button>

          {denyList.length > 0 ? (
            <Table>
              <TableHead>
                <TableCell>{t`Date added`}</TableCell>
                <TableCell>
                  {t`Phone number`}/{t`username`}
                </TableCell>
                <TableCell />
              </TableHead>

              <TableBody>
                {denyList
                  .sort((a, b) => b.date_add - a.date_add)
                  .map((item, i) => (
                    <TableRow key={i}>
                      <Denied
                        denied={item}
                        denyList={denyList}
                        setDenyList={setDenyList}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyBox />
          )}
        </>
      ) : (
        <Preloader />
      )}

      {isOpen && (
        <Editor
          setDenyList={setDenyList}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Section>
  );
};

export default DenyList;
