import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Field = forwardRef(
  (
    {
      preview,
      placeholder,
      isOpen,
      isDisabled,
      isMin,
      isPreviewBold,
      isMulti,
      onClick
    },
    ref
  ) => (
    <div
      ref={ref}
      className={cn('field select__field', {
        field_active: isOpen,
        select__field_disabled: isDisabled,
        select__field_min: isMin
      })}
      onClick={onClick}
    >
      <span
        className={cn('one-line', {
          select__preview: preview,
          select__preview_min: preview && isMin,
          select__preview_bold: isPreviewBold,
          select__preview_flex: isMulti,
          select__placeholder: !preview
        })}
      >
        {preview ? preview : placeholder}
      </span>
    </div>
  )
);

Field.propTypes = {
  preview: PropTypes.node,
  placeholder: PropTypes.string,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMin: PropTypes.bool,
  isPreviewBold: PropTypes.bool,
  isMulti: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Field.defaultProps = {
  preview: '',
  placeholder: '',
  isOpen: false,
  isDisabled: false,
  isMin: false,
  isPreviewBold: false,
  isMulti: false,
  onClick: () => {}
};

export default Field;
