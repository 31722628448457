import React, {useState, useEffect} from 'react';
import Integration from './integration/integration.jsx';
import Grid from '../../../../components/grid/grid.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {getIntegrationListReq} from '../../../../common/api/api.js';

const IntegrationList = () => {
  const {user} = useAppContext();

  const [integrationList, setIntegrationList] = useState();

  const {request} = useRequest();

  const getIntegrationList = async () => {
    const res = await request(
      getIntegrationListReq({
        crm: user.crm,
        domain: user.domain
      })
    );

    setIntegrationList(Object.values(res));
  };

  useEffect(() => {
    getIntegrationList();
  }, []);

  return (
    <div>
      {integrationList ? (
        integrationList.length > 0 ? (
          <Grid>
            {integrationList.map(item => (
              <Integration
                key={item.id}
                integration={item}
              />
            ))}
          </Grid>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default IntegrationList;
