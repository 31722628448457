import React, {useState, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Input from '../../input/input.jsx';
import Select from '../components/select/select.jsx';
import Dropdown from '../components/dropdown/dropdown.jsx';
import OptionList from './option-list/option-list.jsx';
import {
  useRequest,
  useOnClickOutside,
  useOnKeyDown
} from '../../../../common/hooks.js';

const SearchSelect = ({
  input,
  path,
  query,
  locations,
  fromBound,
  toBound,
  isMax,
  onChange
}) => {
  const [suggestionList, setSuggestionList] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef();
  const fieldRef = useRef();

  const {dadataRequest} = useRequest();

  const callback = useCallback(
    _.debounce(async (queryValue, queryLocations) => {
      const res = await dadataRequest({
        path,
        data: {
          query: queryValue,
          locations: queryLocations,
          from_bound: {value: fromBound},
          to_bound: {value: toBound}
        }
      });

      setSuggestionList(
        res.map((item, i) => ({
          value: i,
          label: item.unrestricted_value,
          data: item.data
        }))
      );
    }, 400),
    []
  );

  useEffect(() => {
    fieldRef.current.onfocus = () => setIsFocused(true);
  }, []);

  useEffect(() => {
    query.length ? callback(query, locations) : setSuggestionList([]);
  }, [query]);

  useOnClickOutside({ref: ref, onClick: () => setIsFocused(false)});
  useOnKeyDown({onEscape: () => setIsFocused(false)});

  const handleChange = value => {
    setIsFocused(false);
    onChange(value);
  };

  const isOpen = isFocused && suggestionList.length > 0;

  return (
    <Select
      ref={ref}
      isMax={isMax}
    >
      <Input
        {...input}
        ref={fieldRef}
        isSearch
      />

      {isOpen && (
        <Dropdown fieldRef={fieldRef}>
          <OptionList
            options={suggestionList}
            onChange={handleChange}
          />
        </Dropdown>
      )}
    </Select>
  );
};

SearchSelect.propTypes = {
  input: PropTypes.object,
  path: PropTypes.string,
  query: PropTypes.string,
  locations: PropTypes.array,
  fromBound: PropTypes.string,
  toBound: PropTypes.string,
  isMax: PropTypes.bool,
  onChange: PropTypes.func
};

SearchSelect.defaultProps = {
  input: {},
  path: '',
  query: '',
  locations: [],
  fromBound: '',
  toBound: '',
  isMax: false,
  onChange: () => {}
};

export default SearchSelect;
