import React, {useState} from 'react';
import LegalEntity from './legal-enity/legal-entity.jsx';

const Login = () => {
  return (
    <>
      <LegalEntity />
    </>
  );
};

export default Login;
