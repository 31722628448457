import React from 'react';
import Bitrix24 from './bitrix24/bitrix24.jsx';
import Amocrm from './amocrm/amocrm.jsx';
import Alfacrm from "./alfacrm/alfacrm";
import Grid from '../../../../grid/grid.jsx';
import Preloader from '../../../../preloader/preloader.jsx';
import {useConnectionContext} from '../../connection-context.js';
import {useSettingsContext} from '../settings-context.js';

const Config = () => {
  const {justCrm} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length > 0 && (
          <Grid
            elements='auto'
            gap={8}
          >
            {justCrm === 'bitrix24' && <Bitrix24 />}
            {justCrm === 'amocrm' && <Amocrm />}
            {justCrm === 'alfacrm' && <Alfacrm />}
          </Grid>
        )
      ) : (
        <Preloader
          isMin
          style={{padding: 0}}
        />
      )}
    </>
  );
};

export default Config;
