import React, {useEffect, forwardRef} from 'react';
import {useFormikContext, useField} from 'formik';
import {t} from 'ttag';
import {Input} from '../../../../form/form.jsx';
import {useConnectionContext} from '../../connection-context.js';

const Label = forwardRef((props, ref) => {
  const {connection} = useConnectionContext();

  const {submitForm} = useFormikContext();
  const [field, , helpers] = useField('label');

  useEffect(() => {
    helpers.setValue(connection.label || t`Connection ${connection.id}`);
  }, [connection.label]);

  return (
    <Input
      ref={ref}
      {...field}
      isTitle
      onChange={helpers.setValue}
      onBlur={submitForm}
    />
  );
});

export default Label;
