import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Link = ({
  children,
  href,
  target,
  color,
  isFake,
  isSmall,
  isBold,
  isUnderlined,
  style,
  onClick
}) => {
  const rel = target === '_blank' ? 'noreferrer' : null;

  const params = {
    className: cn('link', {
      link_s: isSmall,
      link_bold: isBold,
      [`link_${color}`]: color,
      link_underlined: isUnderlined
    }),
    style,
    ...(isFake
      ? {
          onClick: onClick
        }
      : {
          href: href,
          target: target,
          rel: rel
        })
  };

  return (
    <>
      {isFake ? (
        <span {...params}>{children}</span>
      ) : (
        <a {...params}>{children}</a>
      )}
    </>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank']),
  color: PropTypes.oneOf(['dark', 'orange']),
  isFake: PropTypes.bool,
  isSmall: PropTypes.bool,
  isBold: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired
};

Link.defaultProps = {
  children: <></>,
  href: '#',
  target: '_self',
  color: null,
  isFake: false,
  isSmall: false,
  isBold: false,
  isUnderlined: false,
  style: {},
  onClick: () => {}
};

export default Link;
