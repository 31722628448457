import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Wrapper = ({
  children,
  justifyContent,
  alignItems,
  gap,
  width,
  isColumn,
  noWrap,
  isClickable,
  style,
  onClick
}) => (
  <div
    className={cn('wrapper', {
      wrapper_column: isColumn,
      wrapper_nowrap: noWrap,
      'wrapper_justify-content-space-between':
        justifyContent === 'space-between',
      'wrapper_align-items-flex-start': alignItems === 'flex-start',
      'wrapper_align-items-center': alignItems === 'center',
      [`wrapper_gap-${gap}`]: gap,
      [`wrapper_width-${width}`]: width,
      wrapper_clickable: isClickable
    })}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  gap: PropTypes.number,
  width: PropTypes.oneOf(['', 'max-content']),
  isColumn: PropTypes.bool,
  noWrap: PropTypes.bool,
  isClickable: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
};

Wrapper.defaultProps = {
  children: <></>,
  justifyContent: '',
  alignItems: '',
  gap: 8,
  width: '',
  isColumn: false,
  noWrap: false,
  isClickable: false,
  style: {},
  onClick: () => {}
};

export default Wrapper;
