import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import {t} from 'ttag';
import Fullname from './fullname/fullname.jsx';
import Email from './email/email.jsx';
import Phone from './phone/phone.jsx';
import AccessInstances from './access-instances/access-instances.jsx';
import Popup from '../../../../../components/popup/popup.jsx';
import * as Title from '../../../../../components/title/title.jsx';
import Line from '../../../../../components/line/line.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../../../components/button/button.jsx';

const Editor = ({title, initialValues, onSubmit, onClose}) => {
  const validationSchema = yup.object().shape({
    fullname: yup.string().trim().required(),
    email: yup.string().email().required(),
    phone: yup.string(),
    access_instances: yup.string()
  });

  return (
    <Popup onClick={onClose}>
      <Title.H2 style={{marginBottom: 16}}>{title}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Fullname />
          <Email />
          <Phone />
          <AccessInstances />
          <Line />

          <Wrapper alignItems="center">
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClose}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

Editor.propTypes = {
  title: PropTypes.string,
  initialValues: PropTypes.shape({
    fullname: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    access_instances: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Editor.defaultProps = {
  title: '',
  initialValues: {
    fullname: '',
    email: '',
    phone: '',
    access_instances: ''
  },
  onSubmit: () => {},
  onClose: () => {}
};

export default Editor;
