import React from 'react';
import moment from 'moment';
import {t} from 'ttag';
import * as Title from '../../../../title/title.jsx';
import Text from '../../../../text/text.jsx';
import {usePaymentContext} from '../../payment-context.js';
import {useTariffPlanContext} from '../tariff-plan-context.js';

const TariffHead = () => {
  const {connection} = usePaymentContext();
  const {tariffOptions} = useTariffPlanContext();

  const {packet_name} = tariffOptions[0];

  const expirationDate =
    connection.tariff_plane === packet_name && connection.date_subscription
      ? moment.unix(connection.date_subscription).format('L')
      : null;

  return (
    <div className="tariff-plan__head">
      <Title.H1>{packet_name}</Title.H1>

      {expirationDate && (
        <Text color="white">
          {t`till`} {expirationDate}
        </Text>
      )}
    </div>
  );
};

export default TariffHead;
