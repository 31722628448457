import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import Actions from './actions/actions.jsx';
import {TableCell} from '../../../../../../components/table/table.jsx';
import EmployeeContext from './employee-context.js';
import {useHomeContext} from '../../../../home-context.js';

const Employee = ({employee}) => {
  const {connectionList} = useHomeContext();

  const accessInstances = employee.access_instances
    ? employee.access_instances
        .substring(1, employee.access_instances.length - 1)
        .split(', ')
        .map(item => parseInt(item))
    : [];

  const availableConnectionIdList = accessInstances.filter(item =>
    connectionList.find(connection => connection.id === item)
  );

  const firstAvailableConnection = connectionList.find(
    item => item.id === availableConnectionIdList[0]
  );

  return (
    <EmployeeContext.Provider value={{employee}}>
      <TableCell>{employee.fullname}</TableCell>
      <TableCell>{employee.email}</TableCell>
      <TableCell>{employee.phone}</TableCell>

      <TableCell>
        {accessInstances.length
          ? firstAvailableConnection && (
              <>
                {firstAvailableConnection.label ||
                  `${t`Connection`} ${firstAvailableConnection.id}`}

                {availableConnectionIdList.length > 1 &&
                  ` + ${availableConnectionIdList.length - 1}`}
              </>
            )
          : t`All`}
      </TableCell>

      <Actions />
    </EmployeeContext.Provider>
  );
};

Employee.propTypes = {
  employee: PropTypes.shape({
    access_instances: PropTypes.string,
    access_token: PropTypes.string,
    amo_id: PropTypes.number,
    amojo_id: PropTypes.string,
    crm_user_id: PropTypes.number,
    email: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
    instance_id: PropTypes.number,
    integration_id: PropTypes.number,
    is_deleted: PropTypes.number,
    lang: PropTypes.string,
    phone: PropTypes.string,
    refresh_token: PropTypes.string,
    uuid: PropTypes.string
  })
};

export default Employee;
