import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import update from 'immutability-helper';
import moment from 'moment';
import cn from 'classnames';
import {t} from 'ttag';
import Closer from '../../../../components/closer/closer.jsx';
import Wrapper from '../../../../components/wrapper/wrapper.jsx';
import * as Title from '../../../../components/title/title.jsx';
import Text from '../../../../components/text/text.jsx';
import {
  getFromLocalStorage,
  putInLocalStorage
} from '../../../../common/actions.js';

const Notice = ({index, notice, setNoticeList}) => {
  const updateIsShown = value =>
    setNoticeList(prevValue =>
      update(prevValue, {
        [index]: {
          is_shown: {$set: value}
        }
      })
    );

  useEffect(() => {
    const isHidden = getFromLocalStorage(`hiddenNotice-${notice.id}`);
    updateIsShown(!isHidden);
  }, []);

  const handleClick = () => {
    updateIsShown(false);
    putInLocalStorage(`hiddenNotice-${notice.id}`, true);
  };

  const date = moment.unix(notice.date_add).format('L');

  return (
    <>
      {notice.is_shown && (
        <div className="notice-list__container">
          <div
            className={cn('notice-list__item', {
              'notice-list__item_warning': notice.alert_type === 1,
              'notice-list__item_danger': notice.alert_type === 2,
              'notice-list__item_success': notice.alert_type === 3
            })}
          >
            <Closer
              color="true-transparent"
              onClick={handleClick}
            />

            <div
              className={cn('notice-list__icon', {
                'notice-list__icon_warning': notice.alert_type === 1,
                'notice-list__icon_danger': notice.alert_type === 2,
                'notice-list__icon_success': notice.alert_type === 3
              })}
            />

            <div>
              <Title.H3 style={{marginBottom: 16}}>{notice.title}</Title.H3>

              <Text
                isSmall
                style={{marginBottom: 24}}
              >
                {notice.short_description}
              </Text>
            </div>

            <Wrapper
              justifyContent="space-between"
              alignItems="center"
            >
              <Text isSmall>{date}</Text>

              <Link
                className="link link_s link_light link_underlined"
                to={`/notices/${notice.id}`}
              >
                {t`read more`}
              </Link>
            </Wrapper>
          </div>
        </div>
      )}
    </>
  );
};

Notice.propTypes = {
  index: PropTypes.number,
  notice: PropTypes.shape({
    alert_type: PropTypes.number,
    date_add: PropTypes.number,
    date_end: PropTypes.number,
    date_start: PropTypes.number,
    description: PropTypes.string,
    id: PropTypes.number,
    integration_type_id: PropTypes.number,
    is_deleted: PropTypes.number,
    is_shown: PropTypes.bool,
    lang: PropTypes.string,
    short_description: PropTypes.string,
    title: PropTypes.string
  }),
  setNoticeList: PropTypes.func
};

Notice.defaultProps = {
  index: -1,
  notice: {},
  setNoticeList: () => {}
};

export default Notice;
