import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import Popup from '../../../../components/popup/popup.jsx';
import * as Title from '../../../../components/title/title.jsx';
import Nav, {NavBar, NavTab} from '../../../../components/nav/nav.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useMenuContext} from '../menu-context.js';

import Profile from './profile/profile.jsx';
import TelphinCustomer from './telphin-customer/telphin-customer.jsx';
// import Partnership from './partnership/partnership.jsx';
// import WhatcrmWeb from './whatcrm-web/whatcrm-web.jsx';
// import Notifications from './notifications/notifications.jsx';
import Theme from './theme/theme.jsx';
import Bitrix24 from './bitrix24/bitrix24.jsx';

const Settings = () => {
  const {user, lang, isTelphinClientFormOpen, setIsTelphinClientFormOpen} =
      useAppContext();

  const {setIsSettingsOpen} = useMenuContext();
  const [tab, setTab] = useState('profile');

  useEffect(() => {
    if (isTelphinClientFormOpen) {
      setTab('telphinCustomer');
      setIsTelphinClientFormOpen(false);
    }
  }, []);

  const options = [
    {
      value: 'profile',
      label: t`Profile`
    }
  ];

  if (lang === 'ru') {
    options.push({
      value: 'telphinCustomer',
      label: 'Юридическое лицо'
    });
  }


  options.push({
    value: 'theme',
    label: t`Theme`
  });

  const isBitrix24 = user.crm === 'B24' || user.crm === 'TELPHGRAM';

  if (isBitrix24) {
    options.push({
      value: 'bitrix24',
      label: t`Bitrix24`
    });
  }

  return (
      <Popup
          isExtraWide
          onClick={() => setIsSettingsOpen(false)}
      >
        <Title.H2 style={{marginBottom: 12}}>{t`Settings`}</Title.H2>

        <Nav
            options={options}
            value={tab}
            onChange={setTab}
        >
          <NavBar style={{marginBottom: 16}} />

          <NavTab tab="profile">
            <Profile />
          </NavTab>

          {lang === 'ru' && (
              <NavTab tab="telphinCustomer">
                <TelphinCustomer />
              </NavTab>
          )}

          <NavTab tab="theme">
            <Theme />
          </NavTab>

          {isBitrix24 && (
              <NavTab tab="bitrix24">
                <Bitrix24 />
              </NavTab>
          )}
        </Nav>
      </Popup>
  );
};

export default Settings;
