import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Table = ({children, isEmbedded, style}) => (
  <div className="table__container">
    <table
      className={cn('table', {
        table_embedded: isEmbedded
      })}
      style={style}
    >
      {children}
    </table>
  </div>
);

Table.propTypes = {
  children: PropTypes.node,
  isEmbedded: PropTypes.bool,
  style: PropTypes.object
};

Table.defaultProps = {
  children: <></>,
  isEmbedded: false,
  style: {}
};

export {TableHead} from './table-head/table-head.jsx';
export {TableBody} from './table-body/table-body.jsx';
export {TableRow} from './table-row/table-row.jsx';
export {TableCell} from './table-cell/table-cell.jsx';

export default Table;
