import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import * as Title from '../title/title.jsx';
import Icons from '../icons/icons.jsx';

const Preloader = ({isMin, style}) => (
  <div
    className="placeholder"
    style={style}
  >
    <Icons.Spinner
      className="icon_spinner"
      style={{marginBottom: 8}}
    />

    {!isMin && <Title.H3>{t`Loading...`}</Title.H3>}
  </div>
);

Preloader.propTypes = {
  isMin: PropTypes.bool,
  style: PropTypes.object
};

export default Preloader;
