import React from 'react';
import {t} from 'ttag';
import Employee from './employee/employee.jsx';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '../../../../../components/table/table.jsx';
import {useStaffContext} from '../staff-context.js';

const EmployeeList = () => {
  const {visibleEmployeeList} = useStaffContext();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Employee`}</TableCell>

        <TableCell>{t`Email`}</TableCell>

        <TableCell>{t`Phone number`}</TableCell>

        <TableCell>{t`Available connections`}</TableCell>

        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {visibleEmployeeList.map(item => (
          <TableRow key={item.id}>
            <Employee employee={item} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EmployeeList;
