import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {t} from 'ttag';
import Config from './config/config.jsx';
import Button from '../../../button/button.jsx';
import Icons from '../../../icons/icons.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useRequest} from '../../../../common/hooks.js';
import {createConnectionReq} from '../../../../common/api/api.js';
import {getConnectionCondition} from '../../../../common/actions.js';

const Creating = () => {
  const {setNotificationList} = useAppContext();

  const {
    client,
    setConnectionList,
    setOpenConnectionId,
    setConnectionRequiringPreset,
    onCreate
  } = useConnectionListContext();

  const [isOpen, setIsOpen] = useState(false);

  const {request} = useRequest();

  const createConnection = async values => {
    const res = await request(
      createConnectionReq({
        crm: client.crm,
        domain: client.domain,
        ...values
      })
    );

    if (Object.keys(res).length) {
      setConnectionList(prevValue =>
        update(prevValue, {
          $push: [{...res, ...getConnectionCondition(res)}]
        })
      );

      onCreate(res);

      if (client.isCustomer) {
        setNotificationList(prevValue =>
          update(prevValue, {
            $push: [
              {title: `${t`Connection`} ${res.id} ${t`has been created.`}`}
            ]
          })
        );

        return;
      }

      if (client.crm === 'AMO' || client.crm === 'TELEGRAM') {
        setConnectionRequiringPreset(res.id);
      }

      setOpenConnectionId(res.id);
    }
  };

  const handleClick = () => {
    if (client.crm === 'LK') {
      setIsOpen(true);
      return;
    }

    createConnection({
      version:
        client.crm === 'B24' || client.crm === 'AMO'
          ? 'whatsapp'
          : client.crm === 'TELEGRAM' || client.crm === 'TELPHGRAM'
          ? 'telegram'
          : ''
    });
  };

  const handleCreate = values => {
    createConnection(values);
    setIsOpen(false);
    return true;
  };

  return (
    <>
      <Button onClick={handleClick}>
        <Icons.Plus />
        {t`Create connection`}
      </Button>

      {isOpen && (
        <Config
          onCreate={handleCreate}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

Creating.propTypes = {
  onCreate: PropTypes.func
};

Creating.defaultProps = {
  onCreate: () => {}
};

export default Creating;
