import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button.jsx';
import Icons from '../icons/icons.jsx';
import Preloader from '../preloader/preloader.jsx';

const QR = ({qr, isPending, style, onClick}) => (
  <div
    className="qr"
    style={{
      ...style,
      backgroundImage: qr && `url("${qr}")`
    }}
  >
    {isPending ? (
      <Button
        color="transparent"
        onClick={onClick}
      >
        <Icons.Refresh />
      </Button>
    ) : (
      !qr && <Preloader isMin />
    )}
  </div>
);

QR.propTypes = {
  qr: PropTypes.string,
  isPending: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
};

QR.defaultProps = {
  qr: '',
  isPending: false,
  style: {},
  onClick: () => {}
};

export default QR;
