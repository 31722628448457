import React, {useState} from 'react';
import BasicInfo from './basic-info/basic-info.jsx';
import AdditionalInfo from './additional-info/additional-info.jsx';
import CustomerContext from './customer-context.js';
import {customerPropTypes} from '../prop-types.js';

const Customer = ({customer}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomerContext.Provider value={{customer}}>
      <BasicInfo
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <AdditionalInfo isOpen={isOpen} />
    </CustomerContext.Provider>
  );
};

Customer.propTypes = {
  customer: customerPropTypes.isRequired
};

export default Customer;
