import {t} from 'ttag';

export const filterArray = (search = '', array = [], keys = []) => {
  const simplify = s =>
      s
          ?.toString()
          .replaceAll(' ', '')
          .replaceAll(' ', '')
          .trim()
          .toLowerCase() || '';

  const req = simplify(search);

  return array.filter(item => {
    const el = {
      ...item,
      label:
          typeof item.label === 'string'
              ? item.label
                  ? item.label
                  : `${t`Connection`} ${item.id}`
              : item.label,
      phone: item.phone ? `+${item.phone}` : item.phone
    };

    return Object.entries(el).find(
        ([key, value]) => keys.includes(key) && simplify(value).includes(req)
    );
  });
};

export const getFromLocalStorage = name => {
  try {
    return localStorage.getItem(name);
  } catch {
    return null;
  }
};

export const putInLocalStorage = (name, value) => {
  try {
    localStorage.setItem(name, value);
    return true;
  } catch {
    return false;
  }
};

export const getAppConfig = () => {
  const storageLang = getFromLocalStorage('lang') || '';
  const storageDomain = getFromLocalStorage('domain') || '';

  const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
  );

  let lang = params.lang || storageLang || navigator.language.slice(0, 2);
  lang = lang.replace('la', 'es').replace('br', 'pt').replace('ua', 'uk');

  const crm = params.crm || 'LK';
  let domain = params.domain || storageDomain || '';

  if (domain.slice(-2) === '.m') {
    domain = domain.replace('.m', '.kommo.com');
  }

  const user_id = params.user_id;

  const is_admin = params.is_admin
      ? params.is_admin == '1' || params.is_admin == 'true'
          ? 1
          : 0
      : 1;

  putInLocalStorage('lang', lang);

  if (crm === 'LK') {
    putInLocalStorage('domain', domain);
  }

  return {user: {crm, domain, user_id, is_admin}, lang};
};

export const isolateNumbers = string => string.replace(/[^\d]/g, '');

export const normalizePhoneNumber = phoneNumber => {
  const normalized = isolateNumbers(phoneNumber).substring(0, 15);
  return phoneNumber.substring(0, 1) === '+' ? `+${normalized}` : normalized;
};

export const normalizeUrl = url => {
  const normalized = url.replace('http://', 'https://');

  return 'https://'.includes(normalized.substring(0, 8))
      ? normalized
      : `https://${normalized}`;
};

export const definePosition = ({
                                 triggerPosition = {x: 0, y: 0},
                                 startingPoint = {x: 0, y: 0},
                                 targetSize = {width: 0, height: 0}
                               }) => {
  let left =
      window.innerWidth - triggerPosition.x < targetSize.width
          ? window.innerWidth - targetSize.width - 16
          : triggerPosition.x;

  let top =
      window.innerHeight - triggerPosition.y < targetSize.height
          ? window.innerHeight - targetSize.height - 16
          : triggerPosition.y;

  top -= startingPoint.y;
  left -= startingPoint.x;

  return {top, left};
};

export const getConnectionCondition = connection => {
  const is_paid = connection.date_subscription * 1000 > Date.now();
  const is_trial = connection.date_trial * 1000 > Date.now();

  const condition = connection.is_premium
      ? 'premium'
      : is_paid
          ? 'paid'
          : is_trial
              ? 'trial'
              : 'unpaid';

  const is_limited =
      condition === 'unpaid' ||
      (!connection.is_premium &&
          !is_trial &&
          is_paid &&
          connection.tariff_plane !== 'Pro');

  return {condition, is_paid, is_limited};
};

export const inflectNumberal = ({value = 0, labels = []}) => {
  const number = parseInt(value);
  const remainder = number % 10;

  return number > 10 && number < 20
      ? labels[2]
      : remainder > 1 && remainder < 5
          ? labels[1]
          : remainder === 1
              ? labels[0]
              : labels[2];
};

export const scrollToPosition = position =>
    setTimeout(() => {
      window.scrollTo({
        top: window.pageYOffset + position - 80,
        behavior: 'smooth'
      });
    }, 200);

export const isIncludes = (str, search) => {
  const clear = s => s.replace(/\s/g, '').toLowerCase();
  return clear(str).includes(clear(search));
};

export const trimValues = values =>
    Object.entries(values).reduce((acc, [key, value]) => {
      acc[key] = typeof value === 'string' ? value.trim() : value;
      return acc;
    }, {});
