import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Select = forwardRef(({children, isMax, style}, ref) => (
  <div
    ref={ref}
    className={cn('select', {select_max: isMax})}
    style={style}
  >
    {children}
  </div>
));

Select.propTypes = {
  children: PropTypes.node,
  isMax: PropTypes.bool,
  style: PropTypes.object
};

Select.defaultProps = {
  children: <></>,
  isMax: false,
  style: {}
};

export default Select;
