import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Code from './code/code.jsx';
import * as Title from '../../../components/title/title.jsx';
import Text from '../../../components/text/text.jsx';
import Line from '../../../components/line/line.jsx';
import Wrapper from '../../../components/wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../components/button/button.jsx';
import {useAppContext} from '../../../app-context.js';
import {useLoginContext} from '../login-context.js';
import {useRequest} from '../../../common/hooks.js';
import {
  sendVerificationCodeReq,
  checkVerificationCodeReq
} from '../../../common/api/api.js';
import {putInLocalStorage} from '../../../common/actions.js';

const Verification = ({onCancel}) => {
  const {setUser} = useAppContext();
  const {email, setEmail} = useLoginContext();

  const [counter, setCounter] = useState(0);

  const {request} = useRequest();

  const sendVerificationCode = () => request(sendVerificationCodeReq(email));

  useEffect(() => {
    sendVerificationCode();
  }, []);

  const handleSubmit = async values => {
    const {code} = values;

    const res = await request(checkVerificationCodeReq({email, code}));

    if (res.success === 200) {
      putInLocalStorage('domain', email);

      setUser({
        crm: 'LK',
        domain: email
      });

      return true;
    }

    if (counter > 1) {
      onCancel();
      return true;
    }

    setCounter(counter + 1);
    return true;
  };

  const initialValues = {code: ''};

  const validationSchema = yup.object().shape({
    code: yup.string().trim().required().length(8)
  });

  return (
    <>
      <Title.H2 style={{marginBottom: 8}}>{t`Verification code`}</Title.H2>

      <Text style={{marginBottom: 16}}>
        {t`Enter the verification code sent to your email.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Code />
          <Line />

          <Wrapper>
            <SaveButton>{t`Continue`}</SaveButton>

            <Button
              color="white"
              onClick={() => setEmail('')}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

Verification.propTypes = {
  onCancel: PropTypes.func
};

Verification.defaultProps = {
  onCancel: () => {}
};

export default Verification;
