import React from 'react';
import {t} from 'ttag';
import Wrapper from '../../components/wrapper/wrapper.jsx';
import Text from '../../components/text/text.jsx';
import Link from '../../components/link/link.jsx';
import {useAppContext} from '../../app-context.js';

const Footer = () => {
  const {lang, whatsAppSupport} = useAppContext();

  const domain = "chitoff.net";
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Wrapper
        justifyContent="space-between"
        alignItems="center"
        gap={16}
      >
        <Wrapper
          alignItems="center"
          gap={16}
        >
          <Text
            color="dark"
            isSmall
          >
            {`2020 — ${year}`}
          </Text>

          <Text isSmall>
            <Link
              href={`https://${domain}`}
              target="_blank"
              color="dark"
              isSmall
            >
              {domain}
            </Link>
          </Text>
        </Wrapper>

        <Wrapper
          alignItems="center"
          gap={16}
        >
          <Text
            color="dark"
            isSmall
          >
            {t`Contact us on`}
            {' '}
            <Link
              href={whatsAppSupport}
              target="_blank"
              color="dark"
              isSmall
            >
              WhatsApp
            </Link>{' '}
            {t`or`}
            {' '}
            <Link
              href="https://t.me/chitoffnet"
              target="_blank"
              color="dark"
              isSmall
            >
              Telegram
            </Link>
          </Text>

          {lang === 'ru' && (
            <Link
              href="https://chitoff.net/agree"
              target="_blank"
              color="dark"
              isSmall
            >
              Политика обработки персональных данных
            </Link>
          )}
        </Wrapper>
      </Wrapper>
    </footer>
  );
};

export default Footer;
