import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '../../../../form.jsx';

const Option = ({option, matchingOptionIds, onChange}) => {
  const isShown =
    matchingOptionIds.length === 0 || matchingOptionIds.includes(option.value);

  return (
    <>
      {isShown && (
        <div className="select__options-inner">
          <Checkbox
            label={option.label}
            isChecked={option.isChecked}
            onChange={value =>
              onChange({value: option.value, isChecked: value})
            }
          />
        </div>
      )}
    </>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    isChecked: PropTypes.bool
  }),
  matchingOptionIds: PropTypes.array,
  onChange: PropTypes.func
};

Option.defaultProps = {
  option: {},
  matchingOptionIds: [],
  onChange: () => {}
};

export default Option;
