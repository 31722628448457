export const checkIsAccountExistReq = ({crm, domain}) => ({
  path: 'v3/integrations/prop',
  params: {crm, domain}
});

export const sendVerificationCodeReq = email => ({
  path: 'v3/email/send-md5',
  method: 'post',
  data: {email, generate_code: 1}
});

export const checkVerificationCodeReq = ({email, code}) => ({
  path: 'v3/email/prop-code',
  method: 'post',
  data: {email, code}
});

export const registerUserReq = ({email, lang, widget_code}) => ({
  path: 'v3/integrations/install',
  params: {crm: 'LK'},
  method: 'post',
  data: {
    domain: email,
    email,
    lang,
    widget_code,
    users: [
      {
        id: Date.now(),
        name: email,
        email
      }
    ]
  }
});

export const getDashboardReq = ({crm, domain, lang}) => ({
  path: 'v3/dashboard',
  params: {
    crm,
    domain,
    lang
  }
});

export const getConnectionListReq = ({crm, domain}) => ({
  path: 'v3/integrations/instances',
  params: {crm, domain}
});

export const createConnectionReq = ({crm, domain, label, version}) => ({
  path: 'v3/chat/spare',
  params: {
    crm,
    domain,
    ...(version === 'telegram' ? {version} : {})
  },
  method: 'post',
  data: {label}
});

export const getConnectionStatusReq = ({chat_key, full}) => ({
  path: `v3/instance${chat_key}/status`,
  params: full ? {full: 1} : {}
});

export const updateConnectionReq = ({chat_key, data}) => ({
  path: `v3/chat/update`,
  params: {id: chat_key},
  method: 'post',
  data
});

export const deleteConnectionReq = chat_key => ({
  path: 'v3/chat/free',
  params: {id: chat_key}
});

export const getTariffListReq = ({crm, country, lang, isPartner}) => {
  const currency = lang === 'ru' && country !== 'UA' ? 'RUB' : 'USD';

  return {
    path: 'v3/tariffs',
    params: {
      currency: currency,
      crm: crm,
      lang: lang,
      partner: isPartner,
      group: 1
    }
  };
};

export const checkAuthenticationPassword = ({chat_key, password}) => ({
  path: `v3/instance${chat_key}/checkAuthenticationPassword`,
  params: {password}
});

export const getEmployeeListReq = ({crm, domain}) => ({
  path: 'v3/managers/list',
  params: {crm, domain}
});

export const updateEmployeeReq = ({crm, domain, id, data}) => ({
  path: 'v3/managers/update',
  params: {
    crm,
    domain,
    id
  },
  method: 'post',
  data
});

export const deleteEmployeeReq = ({crm, domain, id}) => ({
  path: 'v3/managers/delete',
  params: {
    crm,
    domain,
    id
  }
});

export const createEmployeeReq = ({crm, domain, data}) => ({
  path: 'v3/managers/create',
  params: {crm, domain},
  method: 'post',
  data
});

export const getDenyListReq = ({crm, domain}) => ({
  path: `v3/blacklist`,
  params: {crm, domain}
});

export const createDeniedReq = ({crm, domain, phone}) => ({
  path: `v3/blacklist/create`,
  params: {crm, domain},
  data: {phone},
  method: 'post'
});

export const deleteDeniedReq = ({crm, domain, id}) => ({
  path: `v3/blacklist/delete`,
  params: {crm, domain, id}
});

export const logoutReq = chat_key => ({
  path: `v3/instance${chat_key}/logout`
});

export const getCustomerListReq = ({crm, domain}) => ({
  path: 'v3/partners/list',
  params: {crm, domain}
});

export const addCustomerReq = ({crm, domain, data}) => ({
  path: 'v3/partners/add',
  params: {crm, domain},
  method: 'post',
  data
});

export const deleteCustomerReq = ({crm, domain, data}) => ({
  path: 'v3/partners/delete',
  params: {crm, domain},
  method: 'post',
  data
});

export const cancelPartnershipReq = (crm, domain) => ({
  path: 'v3/partners/cancel',
  params: {crm, domain}
});

export const getPaymentHistoryReq = ({crm, domain, is_partner}) => ({
  path: 'v3/payments',
  params: {
    crm,
    domain,
    is_partner
  }
});

export const updateUserReq = ({crm, domain, data}) => ({
  path: 'v3/integrations/update',
  params: {crm, domain},
  method: 'post',
  data
});

export const createTelphinClientReq = ({crm, domain, data}) => ({
  path: 'v3/telphin/create',
  params: {crm, domain},
  method: 'post',
  data
});

export const getIntegrationListReq = ({crm, domain}) => ({
  path: 'v3/integrations/others',
  params: {crm, domain}
});

export const getIntegratorReq = ({crm, domain}) => ({
  path: 'v3/integrators/index',
  params: {crm, domain}
});

export const getIntegrationTokenReq = ({crm, domain}) => ({
  path: 'v3/integrators/generation',
  params: {crm, domain}
});

export const updateIntegratorReq = ({crm, domain, data}) => ({
  path: 'v3/integrators/update',
  params: {crm, domain},
  method: 'post',
  data
});

export const loadDataReq = ({file, category}) => ({
  path: 'v3/files/load-data',
  params: {category},
  method: 'post',
  data: {body: file},
  isFormData: true
});

export const deleteMessageTemplateReq = ({crm, domain, id}) => ({
  path: 'v3/templates/delete',
  params: {crm, domain, id}
});

export const triggerReviewReq = ({crm, domain}) => ({
  path: 'v3/integrations/review',
  params: {crm, domain}
});

export const createMessageTemplateReq = ({crm, domain, data}) => ({
  path: 'v3/templates/create',
  params: {crm, domain},
  method: 'post',
  data
});

export const updateMessageTemplateReq = ({crm, domain, id, data}) => ({
  path: 'v3/templates/update',
  params: {crm, domain, id},
  method: 'post',
  data
});

export const getAttachmentListReq = ({crm, domain, template_id}) => ({
  path: 'v3/templates/files',
  params: {crm, domain, template_id}
});

export const addAttachmentReq = ({crm, domain, template_id, data}) => ({
  path: 'v3/templates/file-add',
  params: {crm, domain, template_id},
  method: 'post',
  data
});

export const deleteAttachmentReq = ({crm, domain, template_id, id}) => ({
  path: 'v3/templates/file-delete',
  params: {
    crm,
    domain,
    template_id,
    id
  }
});

export const getSubscriptionListReq = ({crm, domain}) => ({
  path: 'v3/subscriptions',
  params: {crm, domain}
});
export const getListReq = ({method, crm, domain}) => ({
    path: `v3/${method}`,
    params: {crm, domain}
});

export const getMessageTemplateAttachmentReq = ({
  crm,
  domain,
  template_id,
  id
}) => ({
  path: 'v3/templates/file',
  params: {crm, domain, template_id, id}
});

export const getNoticeReq = ({crm, id}) => ({
  path: 'v3/notifications/one',
  params: {crm, id}
});

export const getIntegratorClientListReq = ({crm, domain}) => ({
  path: 'v3/integrators/clients',
  params: {crm, domain}
});

export const createPartnerReq = (crm, domain, partnerDomain) => ({
  path: 'v3/integrations/partner',
  params: {crm, domain},
  method: 'post',
  data: {domain_partner: partnerDomain}
});
