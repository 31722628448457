import React from 'react';
import Option from './option/option.jsx';
import {optionsPropTypes} from '../prop-types.js';

const OptionList = ({options}) => (
  <div className="select__options">
    {options.map(item => (
      <Option
        key={item.value}
        option={item}
      />
    ))}
  </div>
);

OptionList.propTypes = {
  options: optionsPropTypes
};

export default OptionList;
