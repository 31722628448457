import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';
import {Toggle} from '../../../../../../form/form.jsx';
import Tooltip from '../../../../../../tooltip/tooltip.jsx';
import Text from '../../../../../../text/text.jsx';
import {useConnectionContext} from '../../../../connection-context.js';
import {useSettingsContext} from '../../../settings-context.js';

const Group = () => {
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField('is_group');
  const {value} = field;
  const {setValue} = helpers;

  return (
    <Wrapper alignItems="center">
      <Toggle
        label={t`Working with groups`}
        isChecked={value}
        isDisabled={connection.is_limited}
        onChange={setValue}
        onClick={() => connection.is_limited && setIsAlertOpen(true)}
      />

      <Tooltip>
        <Text>
          {t`Group chats allow you to chat with several people together. A deal containing the entire message history is created when a participant replies.`}
        </Text>
      </Tooltip>
    </Wrapper>
  );
};

export default Group;
