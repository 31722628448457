import React, {useState} from 'react';
import {t} from 'ttag';
import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Text from '../../../../../text/text.jsx';
import Button from '../../../../../button/button.jsx';
import Link from '../../../../../link/link.jsx';
import Icons from '../../../../../icons/icons.jsx';
import Confirmation from '../../../../../confirmation/confirmation.jsx';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useRequest} from '../../../../../../common/hooks.js';
import {logoutReq} from '../../../../../../common/api/api.js';

const Output = () => {
  const {client, setOpenConnectionId} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {request} = useRequest();

  const logout = async () => {
    const res = await request(logoutReq(connection.chat_key));

    if (res.success || res._) {
      setIsConfirmationOpen(false);
      setOpenConnectionId(connection.id);
    }

    return true;
  };

  return (
    <>
      {client.isCustomer ? (
        connection.phone && (
          <Text>
            {t`Phone number:`} +{connection.phone}
          </Text>
        )
      ) : (
        <Wrapper
          alignItems="center"
          gap={0}
        >
          <Text isSpan>
            {t`Phone number:`}
            {' '}
          </Text>

          {connection.phone ? (
            <Wrapper
              alignItems="center"
              gap={0}
            >
              <Text isSpan>+{connection.phone}</Text>

              <Button
                color="transparent"
                style={{height: 16}}
                onClick={() => setIsConfirmationOpen(true)}
              >
                <Icons.Pencil />
              </Button>
            </Wrapper>
          ) : (
            <Link
              isFake
              isUnderlined
              onClick={() => setOpenConnectionId(connection.id)}
            >
              {connection.version === 'whatsapp'
                ? t`connect WhatsApp`
                : connection.version === 'telegram'
                ? t`connect Telegram`
                : t`connect`}
            </Link>
          )}
        </Wrapper>
      )}

      {isConfirmationOpen && (
        <Confirmation
          title={t`Are you sure you want to change your phone number?`}
          onClick={res => (res ? logout() : setIsConfirmationOpen(false))}
        />
      )}
    </>
  );
};

export default Output;
