import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';

import {SingleSelect} from '../../../form/form.jsx';
import {useConnectionListContext} from '../../connection-list-context.js';

import Label from './label/label.jsx';

const Categorization = () => {
  const {connectionList} = useConnectionListContext();
  const [field, , helpers] = useField('category');

  const inactiveNumber = connectionList?.filter(item => !item.phone).length;
  const unpaidNumber = connectionList?.filter(item => !item.is_paid).length;

  const unconfiguredNumber = connectionList?.filter(
    item => item.is_configured === false
  ).length;

  const options = [
    {value: 'all', label: t`All connections`},
    inactiveNumber
      ? {
          value: 'inactive',
          label: (
            <Label
              text={t`Inactive`}
              number={inactiveNumber}
            />
          )
        }
      : undefined,
    unpaidNumber
      ? {
          value: 'unpaid',
          label: (
            <Label
              text={t`Awaiting payment`}
              number={unpaidNumber}
            />
          )
        }
      : undefined,
    unconfiguredNumber
      ? {
          value: 'unconfigured',
          label: (
            <Label
              text={t`Unconfigured`}
              number={unconfiguredNumber}
            />
          )
        }
      : undefined
  ].filter(item => item);

  return (
    <>
      {options.length > 1 && (
        <SingleSelect
          options={options}
          value={field.value}
          isMin
          isPreviewBold
          style={{flex: '1 0', maxWidth: 162}}
          onChange={helpers.setValue}
        />
      )}
    </>
  );
};

export default Categorization;
