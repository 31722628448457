import React from 'react';
import {t} from 'ttag';
import * as Title from '../../../components/title/title.jsx';
import Text from '../../../components/text/text.jsx';
import Line from '../../../components/line/line.jsx';
import Wrapper from '../../../components/wrapper/wrapper.jsx';
import Button from '../../../components/button/button.jsx';
import Link from '../../../components/link/link.jsx';
import {useRequest} from '../../../common/hooks.js';
import {useAppContext} from '../../../app-context.js';
import {useLoginContext} from '../login-context.js';
import {registerUserReq} from '../../../common/api/api.js';

const Registration = () => {
  const {request} = useRequest();
  const {lang} = useAppContext();

  const {email, setEmail, setIsRegistered} = useLoginContext();

  const registerUser = async () => {
    const {widget_code} = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    const res = await request(registerUserReq({email, lang, widget_code}));

    if (res.id) {
      setIsRegistered(true);
    }
  };

  return (
    <>
      <Title.H2 style={{marginBottom: 8}}>{t`Create a new account`}</Title.H2>

      <Text>
        {t`We couldn’t find an account with the specified email address. Do you want to create a new one?`}
      </Text>

      <Line />

      <Wrapper
        alignItems="center"
        style={{marginBottom: 8}}
      >
        <Button onClick={registerUser}>{t`Yes`}</Button>

        <Button
          color="white"
          onClick={() => setEmail()}
        >
          {t`No`}
        </Button>
      </Wrapper>

      <Link
        href="https://chitoff.net/agree"
        target="_blank"
      >
        {t`Personal data processing policy`}
      </Link>
    </>
  );
};

export default Registration;
