import React from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import CompanyName from './company-name/company-name.jsx';
import PhoneNumber from './phone-number/phone-number.jsx';
import Country from './country/country.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import Line from '../../../../line/line.jsx';
import {SaveButton} from '../../../../../components/button/button.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks.js';
import {updateUserReq} from '../../../../../common/api/api.js';
import {trimValues} from '../../../../../common/actions.js';

const Profile = () => {
  const {user, setUser, setNotificationList} = useAppContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await request(
      updateUserReq({
        crm: user.crm,
        domain: user.domain,
        data: trimmedValues
      })
    );

    if (Object.keys(res).length) {
      setUser(
        update(user, {
          title: {$set: res.title},
          phone: {$set: res.phone},
          country: {$set: res.country},
          is_manager: {$set: res.is_manager}
        })
      );

      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: user.domain,
              text: t`Your account information has been updated.`
            }
          ]
        })
      );
    }

    return true;
  };

  const initialValues = {
    country: user.country || '',
    phone: user.phone || '',
    title: user.title || ''
  };

  const validationSchema = yup.object().shape({
    country: yup.string(),
    phone: yup.string(),
    title: yup.string()
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Wrapper
          gap={16}
          isColumn
        >
          <CompanyName />
          <PhoneNumber />
          <Country />
        </Wrapper>

        <Line />
        <SaveButton>{t`Save`}</SaveButton>
      </Form>
    </Formik>
  );
};

export default Profile;
