import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TariffHead from './tariff-head/tariff-head.jsx';
import Body from './tariff-body/tariff-body.jsx';
import TariffPlanContext from './tariff-plan-context.js';
import {usePaymentContext} from '../payment-context.js';

const TariffPlan = ({tariffOptions}) => {
  const {selectedTariffId} = usePaymentContext();

  const {packet_name} = tariffOptions[0];
  const isSelected = tariffOptions.find(item => item.id === selectedTariffId);

  return (
    <TariffPlanContext.Provider value={{tariffOptions, isSelected}}>
      <div
        className={cn(`tariff-plan tariff-plan_${packet_name.toLowerCase()}`, {
          'tariff-plan_selected': isSelected
        })}
      >
        <TariffHead />
        <Body />
      </div>
    </TariffPlanContext.Provider>
  );
};

TariffPlan.propTypes = {
  tariffOptions: PropTypes.array
};

TariffPlan.defaultProps = {
  tariffOptions: []
};

export default TariffPlan;
