import React from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';
import Name from './name/name.jsx';
import Description from './description/description.jsx';
import Logo from './logo/logo.jsx';
import Article from '../../../../../components/article/article.jsx';
import {SaveButton} from '../../../../../components/button/button.jsx';
import Text from '../../../../../components/text/text.jsx';
import WebhookAddress from './webhook-address/webhook-address.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useIntegratorContext} from '../integrator-context.js';
import {useRequest} from '../../../../../common/hooks.js';
import {updateIntegratorReq} from '../../../../../common/api/api.js';
import {trimValues} from '../../../../../common/actions.js';

const Settings = () => {
  const {user, setNotificationList} = useAppContext();
  const {integrator, setIntegrator} = useIntegratorContext();

  const {request} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await request(
      updateIntegratorReq({
        crm: user.crm,
        domain: user.domain,
        data: trimmedValues
      })
    );

    if (Object.keys(res).length) {
      setIntegrator(
        update(integrator, {
          description_short: {$set: res.description_short},
          description: {$set: res.description},
          image: {$set: res.image},
          webhook: {$set: res.webhook}
        })
      );

      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: t`Integrator settings have been updated`
            }
          ]
        })
      );
    }

    return true;
  };

  const initialValues = {
    description_short: integrator.description_short || '',
    description: integrator.description || '',
    image: integrator.image || '',
    webhook: integrator.webhook || ''
  };

  const validationSchema = yup.object().shape({
    description_short: yup.string().trim().required(),
    description: yup.string(),
    image: yup.string(),
    webhook: yup.string()
  });

  return (
    <Article>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Name />
          <Description />
          <Logo />
          <WebhookAddress />
          <SaveButton style={{marginBottom: 8}}>{t`Save`}</SaveButton>

          <Text isSmall>
            {t`*The integration will be removed from publication and sent for moderation after the change.`}
          </Text>
        </Form>
      </Formik>
    </Article>
  );
};

export default Settings;
