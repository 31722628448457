import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../title/title.jsx';
import {Switch} from '../../../../../form/form.jsx';
import Icons from '../../../../../icons/icons.jsx';

const Version = () => {
  const [field, , helpers] = useField('version');

  const {value} = field;
  const {setValue} = helpers;

  const options = [
    {
      value: 'whatsapp',
      label: <Icons.WhatsApp />
    },
    {
      value: 'telegram',
      label: <Icons.Telegram />
    }
  ];

  return (
    <>
      <Title.H2>{t`Choose a messenger`}</Title.H2>

      <Switch
        options={options}
        value={value}
        onChange={setValue}
      />
    </>
  );
};

export default Version;
